[data-rsbs-overlay] {
  border-top-left-radius: 16px;
  border-top-left-radius: var(--rsbs-overlay-rounded,16px);
  border-top-right-radius: 16px;
  border-top-right-radius: var(--rsbs-overlay-rounded,16px);
  display: flex;
  background: #fff;
  background: var(--rsbs-bg,#fff);
  flex-direction: column;
  height: 0px;
  height: var(--rsbs-overlay-h,0px);
  transform: translate3d(0, 0px, 0);
  transform: translate3d(0, var(--rsbs-overlay-translate-y,0px), 0);
  will-change: height;
}

[data-rsbs-overlay]:focus {
  outline: none;
}

[data-rsbs-is-blocking='false'] [data-rsbs-overlay] {
  box-shadow: 0 -5px 60px 0 rgba(38, 89, 115, 0.11),
    0 -1px 0 rgba(38, 89, 115, 0.05);
}

[data-rsbs-overlay],
[data-rsbs-root]:after {
  max-width: auto;
  max-width: var(--rsbs-max-w,auto);
  margin-left: env(safe-area-inset-left);
  margin-left: var(--rsbs-ml,env(safe-area-inset-left));
  margin-right: env(safe-area-inset-right);
  margin-right: var(--rsbs-mr,env(safe-area-inset-right));
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 3;
  overscroll-behavior: none;
  touch-action: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

[data-rsbs-backdrop] {
  top: -60px;
  bottom: -60px;
  background-color: rgba(0, 0, 0, 0.6);
  background-color: var(--rsbs-backdrop-bg,rgba(0, 0, 0, 0.6));
  will-change: opacity;
  cursor: pointer;
  opacity: 1;
}

[data-rsbs-is-dismissable='false'] [data-rsbs-backdrop] {
  cursor: ns-resize;
}

[data-rsbs-root]:after {
  content: '';
  pointer-events: none;
  background: #fff;
  background: var(--rsbs-bg,#fff);
  height: 1px;
  transform-origin: bottom;
  transform: scale3d(1, 0, 1);
  transform: scale3d(1, var(--rsbs-antigap-scale-y,0), 1);
  will-change: transform;
}

[data-rsbs-footer],
[data-rsbs-header] {
  flex-shrink: 0;
  cursor: ns-resize;
  padding: 16px;
}

[data-rsbs-header] {
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  box-shadow: 0 1px 0
    rgba(46, 59, 66, calc(1 * 0.125));
  box-shadow: 0 1px 0
    rgba(46, 59, 66, calc(var(--rsbs-content-opacity,1) * 0.125));
  z-index: 1;
  padding-top: calc(20px + env(safe-area-inset-top));
  padding-bottom: 8px;
}

[data-rsbs-header]:before {
  position: absolute;
  content: '';
  display: block;
  width: 36px;
  height: 4px;
  top: calc(8px + env(safe-area-inset-top));
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  background-color: hsla(0, 0%, 0%, 0.14);
  background-color: var(--rsbs-handle-bg,hsla(0, 0%, 0%, 0.14));
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  [data-rsbs-header]:before {
    transform: translateX(-50%) scaleY(0.75);
  }
}

[data-rsbs-has-header='false'] [data-rsbs-header] {
  box-shadow: none;
  padding-top: calc(12px + env(safe-area-inset-top));
}

[data-rsbs-scroll] {
  flex-shrink: 1;
  flex-grow: 1;
  -webkit-tap-highlight-color: revert;
  -webkit-touch-callout: revert;
  -webkit-user-select: auto;
  -moz-user-select: auto;
       user-select: auto;
  overflow: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}

[data-rsbs-scroll]:focus {
  outline: none;
}

[data-rsbs-has-footer='false'] [data-rsbs-content] {
  padding-bottom: env(safe-area-inset-bottom);
}

[data-rsbs-content] {
  /* The overflow hidden is to ensure any margin on child nodes are included when the resize observer is measuring the height */
  overflow: hidden;
}

[data-rsbs-footer] {
  box-shadow: 0 -1px 0 rgba(46, 59, 66, calc(1 * 0.125)),
    0 2px 0 #fff;
  box-shadow: 0 -1px 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity,1) * 0.125)),
    0 2px 0 var(--rsbs-bg,#fff);
  overflow: hidden;
  z-index: 1;
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
}

[data-rsbs-is-dismissable='true'] [data-rsbs-header] > *, [data-rsbs-is-dismissable='true'] [data-rsbs-scroll] > *, [data-rsbs-is-dismissable='true'] [data-rsbs-footer] > *, [data-rsbs-is-dismissable='false'][data-rsbs-state='opening'] [data-rsbs-header] > *, [data-rsbs-is-dismissable='false'][data-rsbs-state='closing'] [data-rsbs-header] > *, [data-rsbs-is-dismissable='false'][data-rsbs-state='opening'] [data-rsbs-scroll] > *, [data-rsbs-is-dismissable='false'][data-rsbs-state='closing'] [data-rsbs-scroll] > *, [data-rsbs-is-dismissable='false'][data-rsbs-state='opening'] [data-rsbs-footer] > *, [data-rsbs-is-dismissable='false'][data-rsbs-state='closing'] [data-rsbs-footer] > * {
    opacity: 1;
    opacity: var(--rsbs-content-opacity,1);
  }

[data-rsbs-is-dismissable='true'] [data-rsbs-backdrop], [data-rsbs-is-dismissable='false'][data-rsbs-state='opening'] [data-rsbs-backdrop], [data-rsbs-is-dismissable='false'][data-rsbs-state='closing'] [data-rsbs-backdrop] {
    opacity: 1;
    opacity: var(--rsbs-backdrop-opacity,1);
  }

[data-rsbs-state='closed'],
[data-rsbs-state='closing'] {
  /* Allows interactions on the rest of the page before the close transition is finished */
  pointer-events: none;
}

[data-rsbs-overlay] {
	border-top-left-radius: var(--rsbs-overlay-rounded,16px);
	border-top-right-radius: var(--rsbs-overlay-rounded,16px);
	display: flex;
	background: var(--rsbs-bg,#fff);
	flex-direction: column;
	height: var(--rsbs-overlay-h,0px);
	transform: translate3d(0, var(--rsbs-overlay-translate-y,0px), 0);
	will-change: height;
}

[data-rsbs-overlay]:focus {
	outline: none;
}

[data-rsbs-is-blocking="false"] [data-rsbs-overlay] {
	box-shadow: 0 -5px 60px 0 rgba(38, 89, 115, 0.11), 0 -1px 0 rgba(38, 89, 115, 0.05);
}

[data-rsbs-overlay],
[data-rsbs-root]:after {
	max-width: 1200px !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
	z-index: 1200 !important;
	overscroll-behavior: none;
	touch-action: none;
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	-webkit-user-select: none;
	   -moz-user-select: none;
	        user-select: none;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

[data-rsbs-backdrop] {
	top: -60px;
	bottom: -60px;
	background-color: var(--rsbs-backdrop-bg,rgba(0, 0, 0, 0.6));
	will-change: opacity;
	cursor: pointer;
	opacity: 1;
}

[data-rsbs-is-dismissable="false"] [data-rsbs-backdrop] {
	cursor: ns-resize;
}

[data-rsbs-root]:after {
	content: "";
	pointer-events: none;
	background: var(--rsbs-bg,#fff);
	height: 1px;
	transform-origin: bottom;
	transform: scale3d(1, var(--rsbs-antigap-scale-y,0), 1);
	will-change: transform;
}

[data-rsbs-footer],
[data-rsbs-header] {
	flex-shrink: 0;
	cursor: ns-resize;
	padding: 16px;
	box-shadow: none;
}

[data-rsbs-header] {
	text-align: center;
	-webkit-user-select: none;
	   -moz-user-select: none;
	        user-select: none;
	box-shadow: none !important;
	z-index: 1;
	padding-top: calc(20px + env(safe-area-inset-top));
	padding-bottom: 8px;
}

[data-rsbs-header]:before {
	position: absolute;
	content: "";
	display: block;
	width: 36px;
	height: 4px;
	top: calc(8px + env(safe-area-inset-top));
	left: 50%;
	transform: translateX(-50%);
	border-radius: 2px;
	background-color: var(--rsbs-handle-bg,hsla(0, 0%, 0%, 0.14));
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
	[data-rsbs-header]:before {
		transform: translateX(-50%) scaleY(0.75);
	}
}

[data-rsbs-has-header="false"] [data-rsbs-header] {
	box-shadow: none;
	padding-top: calc(12px + env(safe-area-inset-top));
}

[data-rsbs-scroll] {
	flex-shrink: 1;
	flex-grow: 1;
	-webkit-tap-highlight-color: revert;
	-webkit-touch-callout: revert;
	-webkit-user-select: auto;
	-moz-user-select: auto;
	     user-select: auto;
	overflow: auto;
	overscroll-behavior: contain;
	-webkit-overflow-scrolling: touch;
}

[data-rsbs-scroll]:focus {
	outline: none;
}

[data-rsbs-has-footer="false"] [data-rsbs-content] {
	padding-bottom: env(safe-area-inset-bottom);
}

[data-rsbs-content] {
	/* The overflow hidden is to ensure any margin on child nodes are included when the resize observer is measuring the height */
	overflow: hidden;
}

[data-rsbs-footer] {
	box-shadow: none !important;
	overflow: hidden;
	z-index: 1;
	padding-bottom: calc(16px + env(safe-area-inset-bottom));
}

[data-rsbs-is-dismissable="true"] :matches([data-rsbs-header], [data-rsbs-scroll], [data-rsbs-footer]) > *, [data-rsbs-is-dismissable="false"]:matches([data-rsbs-state="opening"], [data-rsbs-state="closing"]) :matches([data-rsbs-header], [data-rsbs-scroll], [data-rsbs-footer]) > * {
		opacity: var(--rsbs-content-opacity,1);
	}

[data-rsbs-is-dismissable="true"] [data-rsbs-backdrop], [data-rsbs-is-dismissable="false"]:matches([data-rsbs-state="opening"], [data-rsbs-state="closing"]) [data-rsbs-backdrop] {
		opacity: var(--rsbs-backdrop-opacity,1);
	}

[data-rsbs-state="closed"],
[data-rsbs-state="closing"] {
	/* Allows interactions on the rest of the page before the close transition is finished */
	pointer-events: none;
}

.mabi-ui-preflight {
    -web-kit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
       tab-size: 4;
    font-family: CeraPro, Roboto, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.mabi-ui-preflight *, *.mabi-ui-preflight, .mabi-ui-preflight::before, .mabi-ui-preflight ::before, .mabi-ui-preflight::after, .mabi-ui-preflight ::after {
    box-sizing: border-box;
    border-width: 0px;
    border-style: solid;
    border-color: #e5e7eb;
    --tw-content: "";
    margin: 0;
    line-height: inherit;
}
.mabi-ui-preflight *:disabled, *.mabi-ui-preflight:disabled, .mabi-ui-preflight::before:disabled, .mabi-ui-preflight ::before:disabled, .mabi-ui-preflight::after:disabled, .mabi-ui-preflight ::after:disabled {
    cursor: default;
}
.mabi-ui-preflight *:-moz-focusring, *.mabi-ui-preflight:-moz-focusring, .mabi-ui-preflight::before:-moz-focusring, .mabi-ui-preflight ::before:-moz-focusring, .mabi-ui-preflight::after:-moz-focusring, .mabi-ui-preflight ::after:-moz-focusring {
    outline: auto;
}
.mabi-ui-preflight *:-moz-ui-invalid, *.mabi-ui-preflight:-moz-ui-invalid, .mabi-ui-preflight::before:-moz-ui-invalid, .mabi-ui-preflight ::before:-moz-ui-invalid, .mabi-ui-preflight::after:-moz-ui-invalid, .mabi-ui-preflight ::after:-moz-ui-invalid {
    box-shadow: none;
}
.mabi-ui-preflight *::-webkit-inner-spin-button, .mabi-ui-preflight *::-webkit-outer-spin-button, *.mabi-ui-preflight::-webkit-inner-spin-button, *.mabi-ui-preflight::-webkit-outer-spin-button, .mabi-ui-preflight::before::-webkit-inner-spin-button, .mabi-ui-preflight::before::-webkit-outer-spin-button, .mabi-ui-preflight ::before::-webkit-inner-spin-button, .mabi-ui-preflight ::before::-webkit-outer-spin-button, .mabi-ui-preflight::after::-webkit-inner-spin-button, .mabi-ui-preflight::after::-webkit-outer-spin-button, .mabi-ui-preflight ::after::-webkit-inner-spin-button, .mabi-ui-preflight ::after::-webkit-outer-spin-button {
    height: auto;
}
.mabi-ui-preflight *::-webkit-search-decoration, *.mabi-ui-preflight::-webkit-search-decoration, .mabi-ui-preflight::before::-webkit-search-decoration, .mabi-ui-preflight ::before::-webkit-search-decoration, .mabi-ui-preflight::after::-webkit-search-decoration, .mabi-ui-preflight ::after::-webkit-search-decoration {
    -webkit-appearance: none;
}
.mabi-ui-preflight *::-webkit-file-upload-button, *.mabi-ui-preflight::-webkit-file-upload-button, .mabi-ui-preflight::before::-webkit-file-upload-button, .mabi-ui-preflight ::before::-webkit-file-upload-button, .mabi-ui-preflight::after::-webkit-file-upload-button, .mabi-ui-preflight ::after::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}
.mabi-ui-preflight hr, hr.mabi-ui-preflight {
    height: 0px;
    color: inherit;
    border-top-width: 1px;
}
.mabi-ui-preflight abbr:where([title]),abbr.mabi-ui-preflight:where([title]) {
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
}
.mabi-ui-preflight h1, h1.mabi-ui-preflight, .mabi-ui-preflight h2, h2.mabi-ui-preflight, .mabi-ui-preflight h3, h3.mabi-ui-preflight, .mabi-ui-preflight h4, h4.mabi-ui-preflight, .mabi-ui-preflight h5, h5.mabi-ui-preflight, .mabi-ui-preflight h6, h6.mabi-ui-preflight {
    font-size: inherit;
    font-weight: inherit;
}
.mabi-ui-preflight a, a.mabi-ui-preflight {
    color: inherit;
    text-decoration: inherit;
}
.mabi-ui-preflight b, b.mabi-ui-preflight, .mabi-ui-preflight strong, strong.mabi-ui-preflight {
    font-weight: bolder;
}
.mabi-ui-preflight code, code.mabi-ui-preflight, .mabi-ui-preflight kbd, kbd.mabi-ui-preflight, .mabi-ui-preflight samp, samp.mabi-ui-preflight, .mabi-ui-preflight pre, pre.mabi-ui-preflight {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
}
.mabi-ui-preflight small, small.mabi-ui-preflight {
    font-size: 80%;
}
.mabi-ui-preflight sub, sub.mabi-ui-preflight, .mabi-ui-preflight sup, sup.mabi-ui-preflight {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
.mabi-ui-preflight sub, sub.mabi-ui-preflight {
    bottom: -0.25em;
}
.mabi-ui-preflight sup, sup.mabi-ui-preflight {
    top: -0.5em;
}
.mabi-ui-preflight table, table.mabi-ui-preflight {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
}
.mabi-ui-preflight button, button.mabi-ui-preflight, .mabi-ui-preflight input, input.mabi-ui-preflight, .mabi-ui-preflight optgroup, optgroup.mabi-ui-preflight, .mabi-ui-preflight select, select.mabi-ui-preflight, .mabi-ui-preflight textarea, textarea.mabi-ui-preflight {
    font-size: 100%;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
}
.mabi-ui-preflight button, .mabi-ui-preflight input, .mabi-ui-preflight optgroup, .mabi-ui-preflight select, .mabi-ui-preflight textarea {
    font-family: inherit;
}
.mabi-ui-preflight button, button.mabi-ui-preflight, .mabi-ui-preflight select, select.mabi-ui-preflight {
    text-transform: none;
}
.mabi-ui-preflight[type=reset], .mabi-ui-preflight[type=submit], button.mabi-ui-preflight, .mabi-ui-preflight[type=button], .mabi-ui-preflight [type=reset], .mabi-ui-preflight [type=submit], .mabi-ui-preflight button, .mabi-ui-preflight [type=button] {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
}
.mabi-ui-preflight progress, progress.mabi-ui-preflight {
    vertical-align: baseline;
}
.mabi-ui-preflight[type=search], .mabi-ui-preflight [type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}
summary.mabi-ui-preflight, .mabi-ui-preflight summary {
    display: list-item;
}
.mabi-ui-preflight blockqoute, blockqoute.mabi-ui-preflight, .mabi-ui-preflight dl, dl.mabi-ui-preflight, .mabi-ui-preflight dd, dd.mabi-ui-preflight, .mabi-ui-preflight h1, h1.mabi-ui-preflight, .mabi-ui-preflight h2, h2.mabi-ui-preflight, .mabi-ui-preflight h3, h3.mabi-ui-preflight, .mabi-ui-preflight h4, h4.mabi-ui-preflight, .mabi-ui-preflight h5, h5.mabi-ui-preflight, .mabi-ui-preflight h6, h6.mabi-ui-preflight, .mabi-ui-preflight hr, hr.mabi-ui-preflight, .mabi-ui-preflight figure, figure.mabi-ui-preflight, .mabi-ui-preflight p, p.mabi-ui-preflight, .mabi-ui-preflight pre, pre.mabi-ui-preflight {
    margin: 0;
}
.mabi-ui-preflight fieldset, fieldset.mabi-ui-preflight {
    margin: 0;
    padding: 0;
}
.mabi-ui-preflight legend, legend.mabi-ui-preflight {
    padding: 0;
}
.mabi-ui-preflight ol, ol.mabi-ui-preflight, .mabi-ui-preflight ul, ul.mabi-ui-preflight, .mabi-ui-preflight menu, menu.mabi-ui-preflight {
    list-style: none;
    margin: 0;
    padding: 0;
}
.mabi-ui-preflight textarea, textarea.mabi-ui-preflight {
    resize: vertical;
}
.mabi-ui-preflight input::-moz-placeholder, .mabi-ui-preflight textarea::-moz-placeholder, input.mabi-ui-preflight::-moz-placeholder, textarea.mabi-ui-preflight::-moz-placeholder {
    opacity: 1;
    color: #9ca3af;
}
.mabi-ui-preflight input::placeholder, .mabi-ui-preflight textarea::placeholder, input.mabi-ui-preflight::placeholder, textarea.mabi-ui-preflight::placeholder {
    opacity: 1;
    color: #9ca3af;
}
.mabi-ui-preflight [role=button], .mabi-ui-preflight[role=button], .mabi-ui-preflight button, button.mabi-ui-preflight {
    cursor: pointer;
}
.mabi-ui-preflight[hidden], .mabi-ui-preflight [hidden] {
    display: none;
}
.mabi-ui-preflight img, img.mabi-ui-preflight, .mabi-ui-preflight svg, svg.mabi-ui-preflight, .mabi-ui-preflight video, video.mabi-ui-preflight, .mabi-ui-preflight canvas, canvas.mabi-ui-preflight, .mabi-ui-preflight audio, audio.mabi-ui-preflight, .mabi-ui-preflight iframe, iframe.mabi-ui-preflight, .mabi-ui-preflight embed, embed.mabi-ui-preflight, .mabi-ui-preflight object, object.mabi-ui-preflight {
    display: block;
    vertical-align: middle;
}
.mabi-ui-preflight img, img.mabi-ui-preflight, .mabi-ui-preflight video, video.mabi-ui-preflight {
    max-width: 100%;
    height: auto;
}
.text-h1 {
    margin: 0;
    padding: 0;
    font-family: CeraPro, Roboto, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 6rem;
    font-weight: 700;
    line-height: 6rem;
    letter-spacing: -0.0156em;
}
.text-h2 {
    margin: 0;
    padding: 0;
    font-family: CeraPro, Roboto, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 3.75rem;
    font-weight: 700;
    line-height: 3.75rem;
    letter-spacing: -0.0083em;
}
.text-h3 {
    margin: 0;
    padding: 0;
    font-family: CeraPro, Roboto, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 3rem;
    font-weight: 700;
    line-height: 3rem;
    letter-spacing: 0em;
}
.text-h4 {
    margin: 0;
    padding: 0;
    font-family: CeraPro, Roboto, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 2.125rem;
    font-weight: 700;
    line-height: 2.125rem;
    letter-spacing: 0.00735em;
}
.text-h5 {
    margin: 0;
    padding: 0;
    font-family: CeraPro, Roboto, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0em;
}
.text-h6 {
    margin: 0;
    padding: 0;
    font-family: CeraPro, Roboto, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.25rem;
    letter-spacing: -0.025em;
}
.text-subtitle1 {
    margin: 0;
    padding: 0;
    font-family: CeraPro, Roboto, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 700;
    line-height: 1rem;
    letter-spacing: 0.00938em;
}
.text-subtitle2 {
    margin: 0;
    padding: 0;
    font-family: CeraPro, Roboto, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 0.875rem;
    letter-spacing: 0.00714em;
}
.text-body1 {
    margin: 0;
    padding: 0;
    font-family: CeraPro, Roboto, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4rem;
    letter-spacing: 0.031em;
}
.text-body2 {
    margin: 0;
    padding: 0;
    font-family: CeraPro, Roboto, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.0178em;
}
.text-caption {
    margin: 0;
    padding: 0;
    font-family: CeraPro, Roboto, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.05rem;
    letter-spacing: 0.03333em;
}
.\!text-caption {
    margin: 0 !important;
    padding: 0 !important;
    font-family: CeraPro, Roboto, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    font-size: 0.75rem !important;
    font-weight: 400 !important;
    line-height: 1.05rem !important;
    letter-spacing: 0.03333em !important;
}
.text-overline {
    margin: 0;
    padding: 0;
    font-family: CeraPro, Roboto, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.625rem;
    letter-spacing: 0.15em;
    text-transform: uppercase;
}
*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}
::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}
.container {
    width: 100%;
}
@media (min-width: 600px) {
    .container {
        max-width: 600px;
    }
}
@media (min-width: 900px) {
    .container {
        max-width: 900px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}
@media (min-width: 1536px) {
    .container {
        max-width: 1536px;
    }
}
.aspect-w-1 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    --tw-aspect-w: 1;
}
.aspect-w-1 > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.aspect-h-1 {
    --tw-aspect-h: 1;
}
.sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
}
.pointer-events-none {
    pointer-events: none !important;
}
.visible {
    visibility: visible !important;
}
.\!visible {
    visibility: visible !important;
}
.collapse {
    visibility: collapse !important;
}
.static {
    position: static !important;
}
.fixed {
    position: fixed !important;
}
.absolute {
    position: absolute !important;
}
.relative {
    position: relative !important;
}
.sticky {
    position: sticky !important;
}
.inset-0 {
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
}
.right-0 {
    right: 0px !important;
}
.top-0 {
    top: 0px !important;
}
.left-\[50\%\] {
    left: 50% !important;
}
.top-\[50\%\] {
    top: 50% !important;
}
.left-0 {
    left: 0px !important;
}
.top-\[12px\] {
    top: 12px !important;
}
.right-2 {
    right: 0.5rem !important;
}
.top-2 {
    top: 0.5rem !important;
}
.left-2 {
    left: 0.5rem !important;
}
.bottom-2 {
    bottom: 0.5rem !important;
}
.left-1\/2 {
    left: 50% !important;
}
.top-1\/2 {
    top: 50% !important;
}
.bottom-0 {
    bottom: 0px !important;
}
.bottom-6 {
    bottom: 1.5rem !important;
}
.left-6 {
    left: 1.5rem !important;
}
.right-1 {
    right: 0.25rem !important;
}
.-top-1 {
    top: -0.25rem !important;
}
.isolate {
    isolation: isolate !important;
}
.z-50 {
    z-index: 50 !important;
}
.z-10 {
    z-index: 10 !important;
}
.z-\[100\] {
    z-index: 100 !important;
}
.z-20 {
    z-index: 20 !important;
}
.z-\[1200\] {
    z-index: 1200 !important;
}
.z-\[1400\] {
    z-index: 1400 !important;
}
.z-\[2\] {
    z-index: 2 !important;
}
.z-40 {
    z-index: 40 !important;
}
.z-0 {
    z-index: 0 !important;
}
.m-0 {
    margin: 0px !important;
}
.m-auto {
    margin: auto !important;
}
.-m-3 {
    margin: -0.75rem !important;
}
.my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}
.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}
.my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
}
.mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}
.ml-auto {
    margin-left: auto !important;
}
.mt-1 {
    margin-top: 0.25rem !important;
}
.mb-\[2px\] {
    margin-bottom: 2px !important;
}
.ml-4 {
    margin-left: 1rem !important;
}
.mt-\[2px\] {
    margin-top: 2px !important;
}
.mt-2 {
    margin-top: 0.5rem !important;
}
.mr-\[12px\] {
    margin-right: 12px !important;
}
.ml-\[12px\] {
    margin-left: 12px !important;
}
.ml-2 {
    margin-left: 0.5rem !important;
}
.mb-3 {
    margin-bottom: 0.75rem !important;
}
.mb-6 {
    margin-bottom: 1.5rem !important;
}
.mt-6 {
    margin-top: 1.5rem !important;
}
.mb-2 {
    margin-bottom: 0.5rem !important;
}
.mr-3 {
    margin-right: 0.75rem !important;
}
.mt-3 {
    margin-top: 0.75rem !important;
}
.mt-10 {
    margin-top: 2.5rem !important;
}
.mt-8 {
    margin-top: 2rem !important;
}
.mt-12 {
    margin-top: 3rem !important;
}
.mt-4 {
    margin-top: 1rem !important;
}
.mb-\[1px\] {
    margin-bottom: 1px !important;
}
.mb-8 {
    margin-bottom: 2rem !important;
}
.mb-0 {
    margin-bottom: 0px !important;
}
.mb-1 {
    margin-bottom: 0.25rem !important;
}
.\!mt-2 {
    margin-top: 0.5rem !important;
}
.mb-4 {
    margin-bottom: 1rem !important;
}
.mr-2 {
    margin-right: 0.5rem !important;
}
.ml-3 {
    margin-left: 0.75rem !important;
}
.mb-16 {
    margin-bottom: 4rem !important;
}
.mt-\[3px\] {
    margin-top: 3px !important;
}
.mt-1\.5 {
    margin-top: 0.375rem !important;
}
.mt-2\.5 {
    margin-top: 0.625rem !important;
}
.box-border {
    box-sizing: border-box !important;
}
.block {
    display: block !important;
}
.inline-block {
    display: inline-block !important;
}
.inline {
    display: inline !important;
}
.flex {
    display: flex !important;
}
.inline-flex {
    display: inline-flex !important;
}
.table {
    display: table !important;
}
.grid {
    display: grid !important;
}
.contents {
    display: contents !important;
}
.list-item {
    display: list-item !important;
}
.hidden {
    display: none !important;
}
.h-10 {
    height: 2.5rem !important;
}
.h-full {
    height: 100% !important;
}
.h-fit {
    height: -moz-fit-content !important;
    height: fit-content !important;
}
.h-\[18px\] {
    height: 18px !important;
}
.h-\[1px\] {
    height: 1px !important;
}
.h-24 {
    height: 6rem !important;
}
.h-14 {
    height: 3.5rem !important;
}
.h-8 {
    height: 2rem !important;
}
.h-12 {
    height: 3rem !important;
}
.h-\[36px\] {
    height: 36px !important;
}
.h-\[20px\] {
    height: 20px !important;
}
.h-\[22px\] {
    height: 22px !important;
}
.h-5 {
    height: 1.25rem !important;
}
.h-\[4px\] {
    height: 4px !important;
}
.h-\[14px\] {
    height: 14px !important;
}
.h-1 {
    height: 0.25rem !important;
}
.h-6 {
    height: 1.5rem !important;
}
.h-4 {
    height: 1rem !important;
}
.h-\[32px\] {
    height: 32px !important;
}
.h-\[60px\] {
    height: 60px !important;
}
.h-screen {
    height: 100vh !important;
}
.h-\[30px\] {
    height: 30px !important;
}
.\!h-\[300px\] {
    height: 300px !important;
}
.h-44 {
    height: 11rem !important;
}
.\!h-\[40vh\] {
    height: 40vh !important;
}
.h-1\/4 {
    height: 25% !important;
}
.h-\[12px\] {
    height: 12px !important;
}
.h-\[40vh\] {
    height: 40vh !important;
}
.h-3 {
    height: 0.75rem !important;
}
.h-1\.5 {
    height: 0.375rem !important;
}
.h-\[calc\(100\%-48px\)\] {
    height: calc(100% - 48px) !important;
}
.h-9 {
    height: 2.25rem !important;
}
.h-2 {
    height: 0.5rem !important;
}
.\!h-\[500px\] {
    height: 500px !important;
}
.h-\[500px\] {
    height: 500px !important;
}
.h-\[300px\] {
    height: 300px !important;
}
.h-\[40px\] {
    height: 40px !important;
}
.h-\[260px\] {
    height: 260px !important;
}
.\!h-\[100svh\] {
    height: 100svh !important;
}
.h-\[90vh\] {
    height: 90vh !important;
}
.max-h-60 {
    max-height: 15rem !important;
}
.max-h-\[400px\] {
    max-height: 400px !important;
}
.max-h-\[min\(28vh\2c 300px\)\] {
    max-height: min(28vh,300px) !important;
}
.min-h-full {
    min-height: 100% !important;
}
.min-h-\[60px\] {
    min-height: 60px !important;
}
.w-full {
    width: 100% !important;
}
.w-10 {
    width: 2.5rem !important;
}
.w-fit {
    width: -moz-fit-content !important;
    width: fit-content !important;
}
.w-1 {
    width: 0.25rem !important;
}
.w-\[18px\] {
    width: 18px !important;
}
.w-\[1px\] {
    width: 1px !important;
}
.w-24 {
    width: 6rem !important;
}
.w-14 {
    width: 3.5rem !important;
}
.w-8 {
    width: 2rem !important;
}
.w-12 {
    width: 3rem !important;
}
.w-\[20px\] {
    width: 20px !important;
}
.w-\[22px\] {
    width: 22px !important;
}
.w-\[280px\] {
    width: 280px !important;
}
.w-\[14px\] {
    width: 14px !important;
}
.w-6 {
    width: 1.5rem !important;
}
.w-\[52px\] {
    width: 52px !important;
}
.w-5 {
    width: 1.25rem !important;
}
.w-4 {
    width: 1rem !important;
}
.w-\[150px\] {
    width: 150px !important;
}
.w-\[420px\] {
    width: 420px !important;
}
.w-\[60px\] {
    width: 60px !important;
}
.w-screen {
    width: 100vw !important;
}
.w-\[30px\] {
    width: 30px !important;
}
.w-auto {
    width: auto !important;
}
.w-\[80px\] {
    width: 80px !important;
}
.w-\[112px\] {
    width: 112px !important;
}
.w-44 {
    width: 11rem !important;
}
.w-1\/4 {
    width: 25% !important;
}
.w-\[350px\] {
    width: 350px !important;
}
.w-\[330px\] {
    width: 330px !important;
}
.w-\[12px\] {
    width: 12px !important;
}
.w-3 {
    width: 0.75rem !important;
}
.w-\[70\%\] {
    width: 70% !important;
}
.w-\[120px\] {
    width: 120px !important;
}
.w-\[calc\(100\%-48px\)\] {
    width: calc(100% - 48px) !important;
}
.w-9 {
    width: 2.25rem !important;
}
.w-\[36px\] {
    width: 36px !important;
}
.w-2 {
    width: 0.5rem !important;
}
.w-20 {
    width: 5rem !important;
}
.w-\[40px\] {
    width: 40px !important;
}
.w-\[50\%\] {
    width: 50% !important;
}
.min-w-\[18px\] {
    min-width: 18px !important;
}
.min-w-\[80px\] {
    min-width: 80px !important;
}
.min-w-\[22px\] {
    min-width: 22px !important;
}
.min-w-\[100px\] {
    min-width: 100px !important;
}
.min-w-\[150px\] {
    min-width: 150px !important;
}
.min-w-\[200px\] {
    min-width: 200px !important;
}
.max-w-screen-sm {
    max-width: 600px !important;
}
.max-w-\[60px\] {
    max-width: 60px !important;
}
.max-w-screen-lg {
    max-width: 1200px !important;
}
.max-w-\[calc\(100\%-70px\)\] {
    max-width: calc(100% - 70px) !important;
}
.max-w-\[190px\] {
    max-width: 190px !important;
}
.max-w-\[420px\] {
    max-width: 420px !important;
}
.max-w-\[250px\] {
    max-width: 250px !important;
}
.max-w-\[330px\] {
    max-width: 330px !important;
}
.max-w-\[400px\] {
    max-width: 400px !important;
}
.max-w-none {
    max-width: none !important;
}
.max-w-\[200px\] {
    max-width: 200px !important;
}
.max-w-\[339px\] {
    max-width: 339px !important;
}
.max-w-full {
    max-width: 100% !important;
}
.flex-1 {
    flex: 1 1 0% !important;
}
.flex-shrink-0 {
    flex-shrink: 0 !important;
}
.shrink-0 {
    flex-shrink: 0 !important;
}
.shrink {
    flex-shrink: 1 !important;
}
.flex-grow {
    flex-grow: 1 !important;
}
.grow {
    flex-grow: 1 !important;
}
.origin-center {
    transform-origin: center !important;
}
.translate-x-1\/2 {
    --tw-translate-x: 50% !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.-translate-y-1\/2 {
    --tw-translate-y: -50% !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-x-\[-50\%\] {
    --tw-translate-x: -50% !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-y-\[-50\%\] {
    --tw-translate-y: -50% !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-x-\[calc\(-50\%_\+_7px\)\] {
    --tw-translate-x: calc(-50% + 7px) !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-y-\[calc\(-100\%_-_7px\)\] {
    --tw-translate-y: calc(-100% - 7px) !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.-translate-x-1\/2 {
    --tw-translate-x: -50% !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-x-5 {
    --tw-translate-x: 1.25rem !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-x-0 {
    --tw-translate-x: 0px !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-y-\[20px\] {
    --tw-translate-y: 20px !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.-translate-x-\[calc\(50\%-40px\)\] {
    --tw-translate-x: calc(calc(50% - 40px) * -1) !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-y-\[6px\] {
    --tw-translate-y: 6px !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.-translate-y-\[calc\(100\%\+10px\)\] {
    --tw-translate-y: calc(calc(100% + 10px) * -1) !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-x-\[40\%\] {
    --tw-translate-x: 40% !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-y-\[-20\%\] {
    --tw-translate-y: -20% !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-x-\[12\%\] {
    --tw-translate-x: 12% !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.-translate-y-\[-8\%\] {
    --tw-translate-y: 8% !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-x-12 {
    --tw-translate-x: 3rem !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-y-12 {
    --tw-translate-y: 3rem !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.rotate-45 {
    --tw-rotate: 45deg !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.rotate-180 {
    --tw-rotate: 180deg !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.scale-95 {
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.scale-100 {
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.scale-\[1\.1\] {
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.-scale-x-100 {
    --tw-scale-x: -1 !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
@keyframes openAccordion {
    from {
        height: 0px;
    }
    to {
        height: var(--radix-accordion-content-height);
    }
}
.animate-openAccordion {
    animation: openAccordion 300ms cubic-bezier(0.87, 0, 0.13, 1) !important;
}
@keyframes closeAccordion {
    from {
        height: var(--radix-accordion-content-height);
    }
    to {
        height: 0px;
    }
}
.animate-closeAccordion {
    animation: closeAccordion 300ms cubic-bezier(0.87, 0, 0.13, 1) !important;
}
@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
.animate-spin {
    animation: spin 1s linear infinite !important;
}
@keyframes pulse {
    50% {
        opacity: .5;
    }
}
.animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
}
.cursor-default {
    cursor: default !important;
}
.cursor-pointer {
    cursor: pointer !important;
}
.cursor-help {
    cursor: help !important;
}
.touch-none {
    touch-action: none !important;
}
.select-none {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
            user-select: none !important;
}
.select-all {
    -webkit-user-select: all !important;
       -moz-user-select: all !important;
            user-select: all !important;
}
.resize {
    resize: both !important;
}
.list-none {
    list-style-type: none !important;
}
.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}
.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}
.grid-cols-\[24px\2c 1fr\] {
    grid-template-columns: 24px 1fr !important;
}
.grid-cols-\[7fr\2c 8fr\] {
    grid-template-columns: 7fr 8fr !important;
}
.grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}
.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}
.flex-row {
    flex-direction: row !important;
}
.flex-col {
    flex-direction: column !important;
}
.flex-col-reverse {
    flex-direction: column-reverse !important;
}
.flex-wrap {
    flex-wrap: wrap !important;
}
.items-start {
    align-items: flex-start !important;
}
.items-end {
    align-items: flex-end !important;
}
.items-center {
    align-items: center !important;
}
.items-stretch {
    align-items: stretch !important;
}
.justify-start {
    justify-content: flex-start !important;
}
.justify-end {
    justify-content: flex-end !important;
}
.justify-center {
    justify-content: center !important;
}
.justify-between {
    justify-content: space-between !important;
}
.justify-around {
    justify-content: space-around !important;
}
.justify-items-center {
    justify-items: center !important;
}
.gap-4 {
    gap: 1rem !important;
}
.gap-2 {
    gap: 0.5rem !important;
}
.gap-\[10px\] {
    gap: 10px !important;
}
.gap-8 {
    gap: 2rem !important;
}
.gap-1 {
    gap: 0.25rem !important;
}
.gap-3 {
    gap: 0.75rem !important;
}
.gap-\[6px\] {
    gap: 6px !important;
}
.gap-12 {
    gap: 3rem !important;
}
.gap-6 {
    gap: 1.5rem !important;
}
.gap-7 {
    gap: 1.75rem !important;
}
.gap-\[2px\] {
    gap: 2px !important;
}
.gap-10 {
    gap: 2.5rem !important;
}
.gap-0 {
    gap: 0px !important;
}
.gap-x-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
}
.gap-y-1 {
    row-gap: 0.25rem !important;
}
.space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}
.self-start {
    align-self: flex-start !important;
}
.self-center {
    align-self: center !important;
}
.self-stretch {
    align-self: stretch !important;
}
.overflow-auto {
    overflow: auto !important;
}
.overflow-hidden {
    overflow: hidden !important;
}
.overflow-visible {
    overflow: visible !important;
}
.overflow-x-auto {
    overflow-x: auto !important;
}
.overflow-y-auto {
    overflow-y: auto !important;
}
.truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}
.whitespace-nowrap {
    white-space: nowrap !important;
}
.whitespace-pre {
    white-space: pre !important;
}
.whitespace-pre-line {
    white-space: pre-line !important;
}
.break-words {
    overflow-wrap: break-word !important;
}
.break-all {
    word-break: break-all !important;
}
.rounded-xl {
    border-radius: 0.75rem !important;
}
.rounded-full {
    border-radius: 9999px !important;
}
.rounded-\[inherit\] {
    border-radius: inherit !important;
}
.rounded-\[4px\] {
    border-radius: 4px !important;
}
.rounded-\[10px\] {
    border-radius: 10px !important;
}
.rounded-lg {
    border-radius: 0.5rem !important;
}
.rounded {
    border-radius: 0.25rem !important;
}
.rounded-3xl {
    border-radius: 1.5rem !important;
}
.rounded-\[8px\] {
    border-radius: 8px !important;
}
.rounded-2xl {
    border-radius: 1rem !important;
}
.rounded-\[100px\] {
    border-radius: 100px !important;
}
.rounded-\[24px\] {
    border-radius: 24px !important;
}
.rounded-\[35px\] {
    border-radius: 35px !important;
}
.rounded-md {
    border-radius: 0.375rem !important;
}
.rounded-\[12px\] {
    border-radius: 12px !important;
}
.rounded-l-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
}
.rounded-r-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
}
.rounded-r-none {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.rounded-l-none {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
.rounded-tl-2xl {
    border-top-left-radius: 1rem !important;
}
.rounded-tr-2xl {
    border-top-right-radius: 1rem !important;
}
.border-\[1px\] {
    border-width: 1px !important;
}
.border-2 {
    border-width: 2px !important;
}
.border-\[2px\] {
    border-width: 2px !important;
}
.border-\[3px\] {
    border-width: 3px !important;
}
.border {
    border-width: 1px !important;
}
.border-0 {
    border-width: 0px !important;
}
.border-t-\[1px\] {
    border-top-width: 1px !important;
}
.border-b-\[1px\] {
    border-bottom-width: 1px !important;
}
.border-solid {
    border-style: solid !important;
}
.border-dashed {
    border-style: dashed !important;
}
.\!border-none {
    border-style: none !important;
}
.border-none {
    border-style: none !important;
}
.border-primary {
    border-color: rgba(0, 253, 199, 1) !important;
}
.border-secondary {
    border-color: rgba(12, 25, 23, 1) !important;
}
.border-tertiary {
    border-color: rgba(242, 76, 85, 1) !important;
}
.border-success {
    border-color: rgba(7, 179, 56, 1) !important;
}
.border-warning {
    border-color: rgba(255, 149, 0, 1) !important;
}
.border-error {
    border-color: rgba(191, 0, 0, 1) !important;
}
.border-loading {
    border-color: rgba(54, 137, 181, 1) !important;
}
.border-black {
    border-color: rgba(0, 0, 0, 1) !important;
}
.border-white {
    border-color: rgba(255, 255, 255, 1) !important;
}
.border-grey {
    border-color: rgba(192, 194, 204, 1) !important;
}
.border-orange {
    border-color: rgba(255, 149, 0, 1) !important;
}
.border-lightblue {
    border-color: rgba(90, 200, 250, 1) !important;
}
.border-blue {
    border-color: rgba(0, 122, 255, 1) !important;
}
.border-indaco {
    border-color: rgba(88, 86, 214, 1) !important;
}
.border-purple {
    border-color: rgba(175, 82, 222, 1) !important;
}
.border-black\/\[0\.38\] {
    border-color: rgb(0 0 0 / 0.38) !important;
}
.border-transparent {
    border-color: transparent !important;
}
.border-black\/\[0\.74\] {
    border-color: rgb(0 0 0 / 0.74) !important;
}
.border-current {
    border-color: currentColor !important;
}
.border-black\/\[0\.12\] {
    border-color: rgb(0 0 0 / 0.12) !important;
}
.border-black\/10 {
    border-color: rgb(0 0 0 / 0.1) !important;
}
.border-\[rgba\(0\2c 0\2c 0\2c 0\.12\)\] {
    border-color: rgba(0,0,0,0.12) !important;
}
.border-nowr-black-a12 {
    border-color: rgba(12, 25, 23, 0.12) !important;
}
.border-nowr-black\/\[0\.12\] {
    border-color: rgb(12 25 23 / 0.12) !important;
}
.border-nowr-black-a60 {
    border-color: rgba(12, 25, 23, 0.60) !important;
}
.border-\[rgba\(12\2c 25\2c 23\2c 0\.12\)\] {
    border-color: rgba(12,25,23,0.12) !important;
}
.border-t-white\/40 {
    border-top-color: rgb(255 255 255 / 0.4) !important;
}
.bg-white {
    background-color: rgba(255, 255, 255, 1) !important;
}
.bg-primary {
    background-color: rgba(0, 253, 199, 1) !important;
}
.bg-secondary {
    background-color: rgba(12, 25, 23, 1) !important;
}
.bg-tertiary {
    background-color: rgba(242, 76, 85, 1) !important;
}
.bg-success {
    background-color: rgba(7, 179, 56, 1) !important;
}
.bg-warning {
    background-color: rgba(255, 149, 0, 1) !important;
}
.bg-error {
    background-color: rgba(191, 0, 0, 1) !important;
}
.bg-loading {
    background-color: rgba(54, 137, 181, 1) !important;
}
.bg-black {
    background-color: rgba(0, 0, 0, 1) !important;
}
.bg-grey {
    background-color: rgba(192, 194, 204, 1) !important;
}
.bg-blue {
    background-color: rgba(0, 122, 255, 1) !important;
}
.bg-lightblue {
    background-color: rgba(90, 200, 250, 1) !important;
}
.bg-indaco {
    background-color: rgba(88, 86, 214, 1) !important;
}
.bg-purple {
    background-color: rgba(175, 82, 222, 1) !important;
}
.bg-orange {
    background-color: rgba(255, 149, 0, 1) !important;
}
.bg-transparent {
    background-color: transparent !important;
}
.bg-black\/\[0\.06\] {
    background-color: rgb(0 0 0 / 0.06) !important;
}
.bg-primary-sl06 {
    background-color: rgba(0,238,187,1) !important;
}
.bg-primary-sl12 {
    background-color: rgba(0,223,175,1) !important;
}
.bg-secondary-sl06 {
    background-color: rgba(27,39,37,1) !important;
}
.bg-secondary-sl12 {
    background-color: rgba(41,53,51,1) !important;
}
.bg-tertiary-sl06 {
    background-color: rgba(243,87,95,1) !important;
}
.bg-tertiary-sl12 {
    background-color: rgba(244,97,105,1) !important;
}
.bg-success-sl06 {
    background-color: rgba(22,184,68,1) !important;
}
.bg-success-sl12 {
    background-color: rgba(37,188,80,1) !important;
}
.bg-warning-sl06 {
    background-color: rgba(240,140,0,1) !important;
}
.bg-warning-sl12 {
    background-color: rgba(224,131,0,1) !important;
}
.bg-error-sl06 {
    background-color: rgba(195,15,15,1) !important;
}
.bg-error-sl12 {
    background-color: rgba(199,31,31,1) !important;
}
.bg-loading-sl06 {
    background-color: rgba(66,144,185,1) !important;
}
.bg-loading-sl12 {
    background-color: rgba(78,151,190,1) !important;
}
.bg-black-sl06 {
    background-color: rgba(15,15,15,1) !important;
}
.bg-black-sl12 {
    background-color: rgba(31,31,31,1) !important;
}
.bg-white-sl06 {
    background-color: rgba(240,255,252,1) !important;
}
.bg-white-sl12 {
    background-color: rgba(224,255,248,1) !important;
}
.bg-grey-sl06 {
    background-color: rgba(180,182,192,1) !important;
}
.bg-grey-sl12 {
    background-color: rgba(169,171,180,1) !important;
}
.bg-orange-sl06 {
    background-color: rgba(240,140,0,1) !important;
}
.bg-orange-sl12 {
    background-color: rgba(224,131,0,1) !important;
}
.bg-lightblue-sl06 {
    background-color: rgba(85,188,235,1) !important;
}
.bg-lightblue-sl12 {
    background-color: rgba(79,176,220,1) !important;
}
.bg-blue-sl06 {
    background-color: rgba(15,130,255,1) !important;
}
.bg-blue-sl12 {
    background-color: rgba(31,138,255,1) !important;
}
.bg-indaco-sl06 {
    background-color: rgba(98,96,216,1) !important;
}
.bg-indaco-sl12 {
    background-color: rgba(108,106,219,1) !important;
}
.bg-purple-sl06 {
    background-color: rgba(180,92,224,1) !important;
}
.bg-purple-sl12 {
    background-color: rgba(185,103,226,1) !important;
}
.bg-secondary\/\[0\.06\] {
    background-color: rgb(12 25 23 / 0.06) !important;
}
.bg-secondary\/\[0\.12\] {
    background-color: rgb(12 25 23 / 0.12) !important;
}
.bg-tertiary\/\[0\.06\] {
    background-color: rgb(242 76 85 / 0.06) !important;
}
.bg-tertiary\/\[0\.12\] {
    background-color: rgb(242 76 85 / 0.12) !important;
}
.bg-success\/\[0\.06\] {
    background-color: rgb(7 179 56 / 0.06) !important;
}
.bg-success\/\[0\.12\] {
    background-color: rgb(7 179 56 / 0.12) !important;
}
.bg-warning\/\[0\.06\] {
    background-color: rgb(255 149 0 / 0.06) !important;
}
.bg-warning\/\[0\.12\] {
    background-color: rgb(255 149 0 / 0.12) !important;
}
.bg-error\/\[0\.06\] {
    background-color: rgb(191 0 0 / 0.06) !important;
}
.bg-error\/\[0\.12\] {
    background-color: rgb(191 0 0 / 0.12) !important;
}
.bg-loading\/\[0\.06\] {
    background-color: rgb(54 137 181 / 0.06) !important;
}
.bg-loading\/\[0\.12\] {
    background-color: rgb(54 137 181 / 0.12) !important;
}
.bg-black\/\[0\.12\] {
    background-color: rgb(0 0 0 / 0.12) !important;
}
.bg-white\/\[0\.06\] {
    background-color: rgb(255 255 255 / 0.06) !important;
}
.bg-white\/\[0\.12\] {
    background-color: rgb(255 255 255 / 0.12) !important;
}
.bg-grey\/\[0\.06\] {
    background-color: rgb(192 194 204 / 0.06) !important;
}
.bg-grey\/\[0\.12\] {
    background-color: rgb(192 194 204 / 0.12) !important;
}
.bg-orange\/\[0\.06\] {
    background-color: rgb(255 149 0 / 0.06) !important;
}
.bg-orange\/\[0\.12\] {
    background-color: rgb(255 149 0 / 0.12) !important;
}
.bg-lightblue\/\[0\.06\] {
    background-color: rgb(90 200 250 / 0.06) !important;
}
.bg-lightblue\/\[0\.12\] {
    background-color: rgb(90 200 250 / 0.12) !important;
}
.bg-blue\/\[0\.06\] {
    background-color: rgb(0 122 255 / 0.06) !important;
}
.bg-blue\/\[0\.12\] {
    background-color: rgb(0 122 255 / 0.12) !important;
}
.bg-indaco\/\[0\.06\] {
    background-color: rgb(88 86 214 / 0.06) !important;
}
.bg-indaco\/\[0\.12\] {
    background-color: rgb(88 86 214 / 0.12) !important;
}
.bg-purple\/\[0\.06\] {
    background-color: rgb(175 82 222 / 0.06) !important;
}
.bg-purple\/\[0\.12\] {
    background-color: rgb(175 82 222 / 0.12) !important;
}
.bg-primary\/\[0\.06\] {
    background-color: rgb(0 253 199 / 0.06) !important;
}
.bg-primary\/\[0\.18\] {
    background-color: rgb(0 253 199 / 0.18) !important;
}
.bg-secondary\/\[0\.18\] {
    background-color: rgb(12 25 23 / 0.18) !important;
}
.bg-tertiary\/\[0\.18\] {
    background-color: rgb(242 76 85 / 0.18) !important;
}
.bg-black\/\[0\.18\] {
    background-color: rgb(0 0 0 / 0.18) !important;
}
.bg-white\/\[0\.18\] {
    background-color: rgb(255 255 255 / 0.18) !important;
}
.bg-grey\/\[0\.18\] {
    background-color: rgb(192 194 204 / 0.18) !important;
}
.bg-loading\/\[0\.18\] {
    background-color: rgb(54 137 181 / 0.18) !important;
}
.bg-warning\/\[0\.18\] {
    background-color: rgb(255 149 0 / 0.18) !important;
}
.bg-success\/\[0\.18\] {
    background-color: rgb(7 179 56 / 0.18) !important;
}
.bg-error\/\[0\.18\] {
    background-color: rgb(191 0 0 / 0.18) !important;
}
.bg-lightblue\/\[0\.18\] {
    background-color: rgb(90 200 250 / 0.18) !important;
}
.bg-blue\/\[0\.18\] {
    background-color: rgb(0 122 255 / 0.18) !important;
}
.bg-indaco\/\[0\.18\] {
    background-color: rgb(88 86 214 / 0.18) !important;
}
.bg-purple\/\[0\.18\] {
    background-color: rgb(175 82 222 / 0.18) !important;
}
.bg-orange\/\[0\.18\] {
    background-color: rgb(255 149 0 / 0.18) !important;
}
.bg-primary\/\[0\.12\] {
    background-color: rgb(0 253 199 / 0.12) !important;
}
.bg-black\/30 {
    background-color: rgb(0 0 0 / 0.3) !important;
}
.bg-white\/\[0\.24\] {
    background-color: rgb(255 255 255 / 0.24) !important;
}
.bg-\[\#F6DEDD\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(246 222 221 / var(--tw-bg-opacity)) !important;
}
.bg-\[\#DDEFE2\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(221 239 226 / var(--tw-bg-opacity)) !important;
}
.bg-primary\/\[0\.24\] {
    background-color: rgb(0 253 199 / 0.24) !important;
}
.bg-secondary\/\[0\.24\] {
    background-color: rgb(12 25 23 / 0.24) !important;
}
.bg-tertiary\/\[0\.24\] {
    background-color: rgb(242 76 85 / 0.24) !important;
}
.bg-success\/\[0\.24\] {
    background-color: rgb(7 179 56 / 0.24) !important;
}
.bg-warning\/\[0\.24\] {
    background-color: rgb(255 149 0 / 0.24) !important;
}
.bg-error\/\[0\.24\] {
    background-color: rgb(191 0 0 / 0.24) !important;
}
.bg-loading\/\[0\.24\] {
    background-color: rgb(54 137 181 / 0.24) !important;
}
.bg-black\/\[0\.24\] {
    background-color: rgb(0 0 0 / 0.24) !important;
}
.bg-grey\/\[0\.24\] {
    background-color: rgb(192 194 204 / 0.24) !important;
}
.bg-orange\/\[0\.24\] {
    background-color: rgb(255 149 0 / 0.24) !important;
}
.bg-lightblue\/\[0\.24\] {
    background-color: rgb(90 200 250 / 0.24) !important;
}
.bg-blue\/\[0\.24\] {
    background-color: rgb(0 122 255 / 0.24) !important;
}
.bg-indaco\/\[0\.24\] {
    background-color: rgb(88 86 214 / 0.24) !important;
}
.bg-purple\/\[0\.24\] {
    background-color: rgb(175 82 222 / 0.24) !important;
}
.bg-grey\/\[0\.38\] {
    background-color: rgb(192 194 204 / 0.38) !important;
}
.bg-white\/\[0\.6\] {
    background-color: rgb(255 255 255 / 0.6) !important;
}
.bg-white\/\[0\.74\] {
    background-color: rgb(255 255 255 / 0.74) !important;
}
.bg-white\/\[0\.38\] {
    background-color: rgb(255 255 255 / 0.38) !important;
}
.bg-\[rgba\(27\2c 32\2c 60\2c 1\)\] {
    background-color: rgba(27,32,60,1) !important;
}
.bg-\[\#F8FFFD\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(248 255 253 / var(--tw-bg-opacity)) !important;
}
.bg-\[\#BF0000\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(191 0 0 / var(--tw-bg-opacity)) !important;
}
.bg-\[rgba\(248\2c 255\2c 253\2c 1\)\] {
    background-color: rgba(248,255,253,1) !important;
}
.bg-gray-200 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity)) !important;
}
.bg-\[rgba\(255\2c 149\2c 0\2c 0\.12\)\] {
    background-color: rgba(255,149,0,0.12) !important;
}
.\!bg-\[\#FF44A8\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 68 168 / var(--tw-bg-opacity)) !important;
}
.bg-gray-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(107 114 128 / var(--tw-bg-opacity)) !important;
}
.bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops)) !important;
}
.bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops)) !important;
}
.bg-\[radial-gradient\(294\.17\%_294\.17\%_at_100\%_100\%\2c _\#0EFDC7_0\%\2c _\#F1FFFC_100\%\)\] {
    background-image: radial-gradient(294.17% 294.17% at 100% 100%, #0EFDC7 0%, #F1FFFC 100%) !important;
}
.bg-\[radial-gradient\(\#FF44A8\2c \#F24C55\)\] {
    background-image: radial-gradient(#FF44A8,#F24C55) !important;
}
.bg-\[radial-gradient\(100\%_400\%_at_100\%_100\%\2c _\#FF44A8_0\%\2c _\#F24C55_100\%\)\] {
    background-image: radial-gradient(100% 400% at 100% 100%, #FF44A8 0%, #F24C55 100%) !important;
}
.from-black\/\[0\.12\] {
    --tw-gradient-from: rgb(0 0 0 / 0.12) !important;
    --tw-gradient-to: rgb(0 0 0 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-white\/\[0\.24\] {
    --tw-gradient-from: rgb(255 255 255 / 0.24) !important;
    --tw-gradient-to: rgb(255 255 255 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-\[\#FF44A8\] {
    --tw-gradient-from: #FF44A8 !important;
    --tw-gradient-to: rgb(255 68 168 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-\[\#F24C55\] {
    --tw-gradient-from: #F24C55 !important;
    --tw-gradient-to: rgb(242 76 85 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.via-transparent {
    --tw-gradient-to: rgb(0 0 0 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to) !important;
}
.via-\[\#FF44A8\] {
    --tw-gradient-to: rgb(255 68 168 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), #FF44A8, var(--tw-gradient-to) !important;
}
.to-transparent {
    --tw-gradient-to: transparent !important;
}
.to-\[\#F24C55\] {
    --tw-gradient-to: #F24C55 !important;
}
.bg-\[length\:20px_20px\] {
    background-size: 20px 20px !important;
}
.fill-white {
    fill: rgba(255, 255, 255, 1) !important;
}
.fill-black {
    fill: rgba(0, 0, 0, 1) !important;
}
.fill-black\/\[0\.38\] {
    fill: rgb(0 0 0 / 0.38) !important;
}
.fill-primary {
    fill: rgba(0, 253, 199, 1) !important;
}
.fill-secondary {
    fill: rgba(12, 25, 23, 1) !important;
}
.fill-tertiary {
    fill: rgba(242, 76, 85, 1) !important;
}
.fill-success {
    fill: rgba(7, 179, 56, 1) !important;
}
.fill-warning {
    fill: rgba(255, 149, 0, 1) !important;
}
.fill-error {
    fill: rgba(191, 0, 0, 1) !important;
}
.fill-loading {
    fill: rgba(54, 137, 181, 1) !important;
}
.fill-blue {
    fill: rgba(0, 122, 255, 1) !important;
}
.fill-lightblue {
    fill: rgba(90, 200, 250, 1) !important;
}
.fill-orange {
    fill: rgba(255, 149, 0, 1) !important;
}
.fill-purple {
    fill: rgba(175, 82, 222, 1) !important;
}
.fill-indaco {
    fill: rgba(88, 86, 214, 1) !important;
}
.fill-grey {
    fill: rgba(192, 194, 204, 1) !important;
}
.stroke-black\/\[0\.06\] {
    stroke: rgb(0 0 0 / 0.06) !important;
}
.stroke-primary {
    stroke: rgba(0, 253, 199, 1) !important;
}
.stroke-secondary {
    stroke: rgba(12, 25, 23, 1) !important;
}
.stroke-tertiary {
    stroke: rgba(242, 76, 85, 1) !important;
}
.stroke-error {
    stroke: rgba(191, 0, 0, 1) !important;
}
.stroke-success {
    stroke: rgba(7, 179, 56, 1) !important;
}
.stroke-warning {
    stroke: rgba(255, 149, 0, 1) !important;
}
.stroke-loading {
    stroke: rgba(54, 137, 181, 1) !important;
}
.stroke-black {
    stroke: rgba(0, 0, 0, 1) !important;
}
.stroke-white {
    stroke: rgba(255, 255, 255, 1) !important;
}
.stroke-grey {
    stroke: rgba(192, 194, 204, 1) !important;
}
.stroke-orange {
    stroke: rgba(255, 149, 0, 1) !important;
}
.stroke-lightblue {
    stroke: rgba(90, 200, 250, 1) !important;
}
.stroke-blue {
    stroke: rgba(0, 122, 255, 1) !important;
}
.stroke-indaco {
    stroke: rgba(88, 86, 214, 1) !important;
}
.stroke-purple {
    stroke: rgba(175, 82, 222, 1) !important;
}
.stroke-black\/\[0\.38\] {
    stroke: rgb(0 0 0 / 0.38) !important;
}
.stroke-2 {
    stroke-width: 2 !important;
}
.object-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
}
.p-0 {
    padding: 0px !important;
}
.p-2 {
    padding: 0.5rem !important;
}
.p-4 {
    padding: 1rem !important;
}
.p-6 {
    padding: 1.5rem !important;
}
.p-1 {
    padding: 0.25rem !important;
}
.p-3 {
    padding: 0.75rem !important;
}
.p-8 {
    padding: 2rem !important;
}
.p-\[14px\] {
    padding: 14px !important;
}
.p-5 {
    padding: 1.25rem !important;
}
.p-\[10px\] {
    padding: 10px !important;
}
.p-1\.5 {
    padding: 0.375rem !important;
}
.py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}
.px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
}
.px-\[2px\] {
    padding-left: 2px !important;
    padding-right: 2px !important;
}
.px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}
.py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}
.px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
.py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
}
.py-\[7px\] {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}
.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}
.px-\[12px\] {
    padding-left: 12px !important;
    padding-right: 12px !important;
}
.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}
.px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
}
.px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.px-2\.5 {
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
}
.py-1\.5 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
}
.px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
}
.py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}
.px-\[10px\] {
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.\!py-\[8\.5px\] {
    padding-top: 8.5px !important;
    padding-bottom: 8.5px !important;
}
.py-3\.5 {
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
}
.py-\[6px\] {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}
.px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
}
.pb-4 {
    padding-bottom: 1rem !important;
}
.pl-1 {
    padding-left: 0.25rem !important;
}
.pr-2 {
    padding-right: 0.5rem !important;
}
.pb-2 {
    padding-bottom: 0.5rem !important;
}
.pt-10 {
    padding-top: 2.5rem !important;
}
.pt-6 {
    padding-top: 1.5rem !important;
}
.pl-0 {
    padding-left: 0px !important;
}
.pt-0 {
    padding-top: 0px !important;
}
.pt-2 {
    padding-top: 0.5rem !important;
}
.pl-\[40px\] {
    padding-left: 40px !important;
}
.pt-4 {
    padding-top: 1rem !important;
}
.pt-\[60px\] {
    padding-top: 60px !important;
}
.pb-12 {
    padding-bottom: 3rem !important;
}
.pt-8 {
    padding-top: 2rem !important;
}
.pl-\[100px\] {
    padding-left: 100px !important;
}
.text-left {
    text-align: left !important;
}
.text-center {
    text-align: center !important;
}
.text-start {
    text-align: start !important;
}
.align-middle {
    vertical-align: middle !important;
}
.text-\[20px\] {
    font-size: 20px !important;
}
.text-\[10px\] {
    font-size: 10px !important;
}
.text-\[4rem\] {
    font-size: 4rem !important;
}
.text-\[3rem\] {
    font-size: 3rem !important;
}
.text-\[3em\] {
    font-size: 3em !important;
}
.text-\[2\.25rem\] {
    font-size: 2.25rem !important;
}
.text-\[1\.5rem\] {
    font-size: 1.5rem !important;
}
.text-\[1rem\] {
    font-size: 1rem !important;
}
.text-\[0\.875rem\] {
    font-size: 0.875rem !important;
}
.text-\[0\.625rem\] {
    font-size: 0.625rem !important;
}
.text-\[24px\] {
    font-size: 24px !important;
}
.text-\[14px\] {
    font-size: 14px !important;
}
.font-bold {
    font-weight: 700 !important;
}
.font-semibold {
    font-weight: 600 !important;
}
.font-normal {
    font-weight: 400 !important;
}
.\!font-bold {
    font-weight: 700 !important;
}
.font-\[600\] {
    font-weight: 600 !important;
}
.uppercase {
    text-transform: uppercase !important;
}
.capitalize {
    text-transform: capitalize !important;
}
.italic {
    font-style: italic !important;
}
.leading-\[0px\] {
    line-height: 0px !important;
}
.leading-3 {
    line-height: .75rem !important;
}
.leading-\[18px\] {
    line-height: 18px !important;
}
.leading-\[4\.25rem\] {
    line-height: 4.25rem !important;
}
.leading-\[3\.75rem\] {
    line-height: 3.75rem !important;
}
.leading-\[3\.125rem\] {
    line-height: 3.125rem !important;
}
.leading-\[2\.5rem\] {
    line-height: 2.5rem !important;
}
.leading-\[1\.625rem\] {
    line-height: 1.625rem !important;
}
.leading-\[1\.25rem\] {
    line-height: 1.25rem !important;
}
.leading-\[1\.125rem\] {
    line-height: 1.125rem !important;
}
.leading-\[0\.75rem\] {
    line-height: 0.75rem !important;
}
.tracking-\[0\.75px\] {
    letter-spacing: 0.75px !important;
}
.tracking-\[0\.002em\] {
    letter-spacing: 0.002em !important;
}
.tracking-\[0\.0025em\] {
    letter-spacing: 0.0025em !important;
}
.tracking-\[0\.02em\] {
    letter-spacing: 0.02em !important;
}
.tracking-widest {
    letter-spacing: 0.1em !important;
}
.text-primary-contrastText {
    color: rgba(0,0,0,1) !important;
}
.text-secondary-contrastText {
    color: rgba(255,255,255,1) !important;
}
.text-tertiary-contrastText {
    color: rgba(255,255,255,1) !important;
}
.text-success-contrastText {
    color: rgba(255,255,255,1) !important;
}
.text-warning-contrastText {
    color: rgba(0,0,0,1) !important;
}
.text-error-contrastText {
    color: rgba(255,255,255,1) !important;
}
.text-loading-contrastText {
    color: rgba(255,255,255,1) !important;
}
.text-black-contrastText {
    color: rgba(255,255,255,1) !important;
}
.text-white-contrastText {
    color: rgba(0, 253, 199, 1) !important;
}
.text-grey-contrastText {
    color: rgba(0,0,0,1) !important;
}
.text-blue-contrastText {
    color: rgba(255,255,255,1) !important;
}
.text-lightblue-contrastText {
    color: rgba(0,0,0,1) !important;
}
.text-indaco-contrastText {
    color: rgba(255,255,255,1) !important;
}
.text-purple-contrastText {
    color: rgba(255,255,255,1) !important;
}
.text-orange-contrastText {
    color: rgba(0,0,0,1) !important;
}
.text-black\/\[0\.74\] {
    color: rgb(0 0 0 / 0.74) !important;
}
.text-black {
    color: rgba(0, 0, 0, 1) !important;
}
.text-black\/\[0\.38\] {
    color: rgb(0 0 0 / 0.38) !important;
}
.text-primary {
    color: rgba(0, 253, 199, 1) !important;
}
.text-secondary {
    color: rgba(12, 25, 23, 1) !important;
}
.text-tertiary {
    color: rgba(242, 76, 85, 1) !important;
}
.text-success {
    color: rgba(7, 179, 56, 1) !important;
}
.text-warning {
    color: rgba(255, 149, 0, 1) !important;
}
.text-error {
    color: rgba(191, 0, 0, 1) !important;
}
.text-loading {
    color: rgba(54, 137, 181, 1) !important;
}
.text-white {
    color: rgba(255, 255, 255, 1) !important;
}
.text-grey {
    color: rgba(192, 194, 204, 1) !important;
}
.text-orange {
    color: rgba(255, 149, 0, 1) !important;
}
.text-lightblue {
    color: rgba(90, 200, 250, 1) !important;
}
.text-blue {
    color: rgba(0, 122, 255, 1) !important;
}
.text-indaco {
    color: rgba(88, 86, 214, 1) !important;
}
.text-purple {
    color: rgba(175, 82, 222, 1) !important;
}
.text-black\/\[0\.12\] {
    color: rgb(0 0 0 / 0.12) !important;
}
.text-black\/70 {
    color: rgb(0 0 0 / 0.7) !important;
}
.text-white\/70 {
    color: rgb(255 255 255 / 0.7) !important;
}
.text-\[\#4B4DED\] {
    --tw-text-opacity: 1 !important;
    color: rgb(75 77 237 / var(--tw-text-opacity)) !important;
}
.\!text-black {
    color: rgba(0, 0, 0, 1) !important;
}
.text-nowr-black-a74 {
    color: rgba(12, 25, 23, 0.74) !important;
}
.text-nowr-black-a38 {
    color: rgba(12, 25, 23, 0.38) !important;
}
.text-nowr-black-a87 {
    color: rgba(12, 25, 23, 0.87) !important;
}
.text-black\/\[0\.6\] {
    color: rgb(0 0 0 / 0.6) !important;
}
.text-black\/60 {
    color: rgb(0 0 0 / 0.6) !important;
}
.text-nowr-black {
    color: rgba(12, 25, 23, 1) !important;
}
.text-nowr-black\/\[0\.38\] {
    color: rgb(12 25 23 / 0.38) !important;
}
.text-nowr-black-a60 {
    color: rgba(12, 25, 23, 0.60) !important;
}
.text-black\/\[0\.24\] {
    color: rgb(0 0 0 / 0.24) !important;
}
.text-nowr-black\/60 {
    color: rgb(12 25 23 / 0.6) !important;
}
.underline {
    text-decoration-line: underline !important;
}
.overline {
    text-decoration-line: overline !important;
}
.line-through {
    text-decoration-line: line-through !important;
}
.no-underline {
    text-decoration-line: none !important;
}
.antialiased {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}
.opacity-100 {
    opacity: 1 !important;
}
.opacity-0 {
    opacity: 0 !important;
}
.opacity-\[0\.38\] {
    opacity: 0.38 !important;
}
.opacity-25 {
    opacity: 0.25 !important;
}
.opacity-70 {
    opacity: 0.7 !important;
}
.shadow-3 {
    --tw-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.12) !important;
    --tw-shadow-colored: 0px 1px 12px 0px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-6 {
    --tw-shadow: 0px 9px 46px 0px rgba(0, 0, 0, 0.12) !important;
    --tw-shadow-colored: 0px 9px 46px 0px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-none {
    --tw-shadow: 0 0 #0000 !important;
    --tw-shadow-colored: 0 0 var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-2 {
    --tw-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.12) !important;
    --tw-shadow-colored: 0px 3px 1px 0px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-1 {
    --tw-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05) !important;
    --tw-shadow-colored: 0px 1px 2px 0px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-\[0px_4px_16px_rgba\(0\2c _0\2c _0\2c _0\.06\)\] {
    --tw-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06) !important;
    --tw-shadow-colored: 0px 4px 16px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-5 {
    --tw-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.12) !important;
    --tw-shadow-colored: 0px 5px 22px 0px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-\[rgba\(0\2c 0\2c 0\2c 0\.06\)\] {
    --tw-shadow-color: rgba(0,0,0,0.06) !important;
    --tw-shadow: var(--tw-shadow-colored) !important;
}
.outline-none {
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
}
.outline {
    outline-style: solid !important;
}
.outline-\[2px\] {
    outline-width: 2px !important;
}
.outline-transparent {
    outline-color: transparent !important;
}
.outline-secondary {
    outline-color: rgba(12, 25, 23, 1) !important;
}
.outline-primary {
    outline-color: rgba(0, 253, 199, 1) !important;
}
.outline-tertiary {
    outline-color: rgba(242, 76, 85, 1) !important;
}
.outline-success {
    outline-color: rgba(7, 179, 56, 1) !important;
}
.outline-warning {
    outline-color: rgba(255, 149, 0, 1) !important;
}
.outline-error {
    outline-color: rgba(191, 0, 0, 1) !important;
}
.outline-loading {
    outline-color: rgba(54, 137, 181, 1) !important;
}
.outline-black {
    outline-color: rgba(0, 0, 0, 1) !important;
}
.outline-white {
    outline-color: rgba(255, 255, 255, 1) !important;
}
.outline-grey {
    outline-color: rgba(192, 194, 204, 1) !important;
}
.outline-orange {
    outline-color: rgba(255, 149, 0, 1) !important;
}
.outline-lightblue {
    outline-color: rgba(90, 200, 250, 1) !important;
}
.outline-blue {
    outline-color: rgba(0, 122, 255, 1) !important;
}
.outline-indaco {
    outline-color: rgba(88, 86, 214, 1) !important;
}
.outline-purple {
    outline-color: rgba(175, 82, 222, 1) !important;
}
.ring-\[1px\] {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}
.ring-\[2px\] {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}
.ring-inset {
    --tw-ring-inset: inset !important;
}
.ring-blue {
    --tw-ring-color: rgba(0, 122, 255, 1) !important;
}
.ring-transparent {
    --tw-ring-color: transparent !important;
}
.ring-primary {
    --tw-ring-color: rgba(0, 253, 199, 1) !important;
}
.ring-secondary {
    --tw-ring-color: rgba(12, 25, 23, 1) !important;
}
.ring-tertiary {
    --tw-ring-color: rgba(242, 76, 85, 1) !important;
}
.ring-black {
    --tw-ring-color: rgba(0, 0, 0, 1) !important;
}
.ring-white {
    --tw-ring-color: rgba(255, 255, 255, 1) !important;
}
.ring-grey {
    --tw-ring-color: rgba(192, 194, 204, 1) !important;
}
.ring-loading {
    --tw-ring-color: rgba(54, 137, 181, 1) !important;
}
.ring-warning {
    --tw-ring-color: rgba(255, 149, 0, 1) !important;
}
.ring-success {
    --tw-ring-color: rgba(7, 179, 56, 1) !important;
}
.ring-error {
    --tw-ring-color: rgba(191, 0, 0, 1) !important;
}
.ring-lightblue {
    --tw-ring-color: rgba(90, 200, 250, 1) !important;
}
.ring-indaco {
    --tw-ring-color: rgba(88, 86, 214, 1) !important;
}
.ring-purple {
    --tw-ring-color: rgba(175, 82, 222, 1) !important;
}
.ring-orange {
    --tw-ring-color: rgba(255, 149, 0, 1) !important;
}
.blur {
    --tw-blur: blur(8px) !important;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.blur-\[40px\] {
    --tw-blur: blur(40px) !important;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.drop-shadow-\[0px_1px_12px_rgba\(0\2c _0\2c _0\2c _0\.12\)\] {
    --tw-drop-shadow: drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.12)) !important;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06)) !important;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.grayscale {
    --tw-grayscale: grayscale(100%) !important;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.invert {
    --tw-invert: invert(100%) !important;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.sepia {
    --tw-sepia: sepia(100%) !important;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.transition-transform {
    transition-property: transform !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
}
.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter !important;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
}
.transition-all {
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
}
.transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
}
.transition-opacity {
    transition-property: opacity !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
}
.duration-200 {
    transition-duration: 200ms !important;
}
.duration-100 {
    transition-duration: 100ms !important;
}
.duration-300 {
    transition-duration: 300ms !important;
}
.ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}
.ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}
.ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}
.will-change-\[transform\2c opacity\] {
    will-change: transform,opacity !important;
}
.scrollbar-hide {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}
.scrollbar-hide::-webkit-scrollbar {
    display: none !important;
}
.line-clamp-2 {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 2 !important;
}
.line-clamp-1 {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 1 !important;
}
.interactive-primary:hover {
    cursor: pointer;
    background-color: rgba(0, 253, 199, 0.06);
}
.interactive-primary:active {
    background-color: rgba(0, 253, 199, 0.12);
}
.stack {
    display: flex;
    flex-direction: column;
}
.\[align-items\:inherit\] {
    align-items: inherit;
}
.\[justify-content\:inherit\] {
    justify-content: inherit;
}
.\[outline-style\:solid\] {
    outline-style: solid;
}
.\[filter\:invert\(80\%\)_sepia\(50\%\)_saturate\(843\%\)_hue-rotate\(101deg\)_brightness\(97\%\)_contrast\(105\%\)\] {
    filter: invert(80%) sepia(50%) saturate(843%) hue-rotate(101deg) brightness(97%) contrast(105%);
}
.\[writing-mode\:vertical-rl\] {
    writing-mode: vertical-rl;
}
.\[text-orientation\:sideways\] {
    text-orientation: sideways;
}
.placeholder\:text-black\/\[0\.38\]::-moz-placeholder {
    color: rgb(0 0 0 / 0.38) !important;
}
.placeholder\:text-black\/\[0\.38\]::placeholder {
    color: rgb(0 0 0 / 0.38) !important;
}
.placeholder\:text-\[inherit\]::-moz-placeholder {
    color: inherit !important;
}
.placeholder\:text-\[inherit\]::placeholder {
    color: inherit !important;
}
.after\:absolute::after {
    content: var(--tw-content) !important;
    position: absolute !important;
}
.after\:top-\[-1px\]::after {
    content: var(--tw-content) !important;
    top: -1px !important;
}
.after\:left-\[-1px\]::after {
    content: var(--tw-content) !important;
    left: -1px !important;
}
.after\:-left-\[1px\]::after {
    content: var(--tw-content) !important;
    left: -1px !important;
}
.after\:-top-\[1px\]::after {
    content: var(--tw-content) !important;
    top: -1px !important;
}
.after\:top-0::after {
    content: var(--tw-content) !important;
    top: 0px !important;
}
.after\:left-0::after {
    content: var(--tw-content) !important;
    left: 0px !important;
}
.after\:z-\[1\]::after {
    content: var(--tw-content) !important;
    z-index: 1 !important;
}
.after\:h-\[calc\(100\%\+2px\)\]::after {
    content: var(--tw-content) !important;
    height: calc(100% + 2px) !important;
}
.after\:h-full::after {
    content: var(--tw-content) !important;
    height: 100% !important;
}
.after\:h-4::after {
    content: var(--tw-content) !important;
    height: 1rem !important;
}
.after\:w-\[calc\(100\%\+2px\)\]::after {
    content: var(--tw-content) !important;
    width: calc(100% + 2px) !important;
}
.after\:w-full::after {
    content: var(--tw-content) !important;
    width: 100% !important;
}
.after\:w-4::after {
    content: var(--tw-content) !important;
    width: 1rem !important;
}
.after\:rounded-\[inherit\]::after {
    content: var(--tw-content) !important;
    border-radius: inherit !important;
}
.after\:rounded-full::after {
    content: var(--tw-content) !important;
    border-radius: 9999px !important;
}
.after\:rounded-3xl::after {
    content: var(--tw-content) !important;
    border-radius: 1.5rem !important;
}
.after\:rounded-bl-md::after {
    content: var(--tw-content) !important;
    border-bottom-left-radius: 0.375rem !important;
}
.after\:border-\[2px\]::after {
    content: var(--tw-content) !important;
    border-width: 2px !important;
}
.after\:border-\[1px\]::after {
    content: var(--tw-content) !important;
    border-width: 1px !important;
}
.after\:border-solid::after {
    content: var(--tw-content) !important;
    border-style: solid !important;
}
.after\:border-transparent::after {
    content: var(--tw-content) !important;
    border-color: transparent !important;
}
.after\:border-primary::after {
    content: var(--tw-content) !important;
    border-color: rgba(0, 253, 199, 1) !important;
}
.after\:border-secondary::after {
    content: var(--tw-content) !important;
    border-color: rgba(12, 25, 23, 1) !important;
}
.after\:border-tertiary::after {
    content: var(--tw-content) !important;
    border-color: rgba(242, 76, 85, 1) !important;
}
.after\:border-error::after {
    content: var(--tw-content) !important;
    border-color: rgba(191, 0, 0, 1) !important;
}
.after\:border-success::after {
    content: var(--tw-content) !important;
    border-color: rgba(7, 179, 56, 1) !important;
}
.after\:border-warning::after {
    content: var(--tw-content) !important;
    border-color: rgba(255, 149, 0, 1) !important;
}
.after\:border-loading::after {
    content: var(--tw-content) !important;
    border-color: rgba(54, 137, 181, 1) !important;
}
.after\:border-black::after {
    content: var(--tw-content) !important;
    border-color: rgba(0, 0, 0, 1) !important;
}
.after\:border-white::after {
    content: var(--tw-content) !important;
    border-color: rgba(255, 255, 255, 1) !important;
}
.after\:border-grey::after {
    content: var(--tw-content) !important;
    border-color: rgba(192, 194, 204, 1) !important;
}
.after\:border-orange::after {
    content: var(--tw-content) !important;
    border-color: rgba(255, 149, 0, 1) !important;
}
.after\:border-lightblue::after {
    content: var(--tw-content) !important;
    border-color: rgba(90, 200, 250, 1) !important;
}
.after\:border-blue::after {
    content: var(--tw-content) !important;
    border-color: rgba(0, 122, 255, 1) !important;
}
.after\:border-indaco::after {
    content: var(--tw-content) !important;
    border-color: rgba(88, 86, 214, 1) !important;
}
.after\:border-purple::after {
    content: var(--tw-content) !important;
    border-color: rgba(175, 82, 222, 1) !important;
}
.after\:bg-white\/\[0\.38\]::after {
    content: var(--tw-content) !important;
    background-color: rgb(255 255 255 / 0.38) !important;
}
.after\:bg-\[rgba\(27\2c 32\2c 60\2c 1\)\]::after {
    content: var(--tw-content) !important;
    background-color: rgba(27,32,60,1) !important;
}
.after\:bg-white::after {
    content: var(--tw-content) !important;
    background-color: rgba(255, 255, 255, 1) !important;
}
.after\:transition-all::after {
    content: var(--tw-content) !important;
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
}
.after\:content-\[\'\'\]::after {
    --tw-content: '' !important;
    content: var(--tw-content) !important;
}
.after\:\[transform\:translateY\(-8px\)_rotate\(-60deg\)_skewX\(-30deg\)_scale\(1\2c _0\.866\)\]::after {
    content: var(--tw-content);
    transform: translateY(-8px) rotate(-60deg) skewX(-30deg) scale(1, 0.866);
}
.hover\:cursor-pointer:hover {
    cursor: pointer !important;
}
.hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
}
.hover\:bg-\[\#F8FFFD\]:hover {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(248 255 253 / var(--tw-bg-opacity)) !important;
}
.hover\:bg-nowr-black-a06:hover {
    background-color: rgba(12, 25, 23, 0.06) !important;
}
.hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
}
.hover\:bg-nowr-black-a12:hover {
    background-color: rgba(12, 25, 23, 0.12) !important;
}
.hover\:bg-primary\/\[0\.06\]:hover {
    background-color: rgb(0 253 199 / 0.06) !important;
}
.focus\:outline-none:focus {
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
}
.focus-visible\:outline-none:focus-visible {
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
}
.focus-visible\:ring-2:focus-visible {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}
.focus-visible\:ring-nowr-black:focus-visible {
    --tw-ring-color: rgba(12, 25, 23, 1) !important;
}
.group:hover .group-hover\:translate-x-1 {
    --tw-translate-x: 0.25rem !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.group:hover .group-hover\:bg-primary\/\[0\.48\] {
    background-color: rgb(0 253 199 / 0.48) !important;
}
.peer:focus-visible ~ .peer-focus-visible\:border-primary {
    border-color: rgba(0, 253, 199, 1) !important;
}
.peer:focus-visible ~ .peer-focus-visible\:border-secondary {
    border-color: rgba(12, 25, 23, 1) !important;
}
.peer:focus-visible ~ .peer-focus-visible\:border-tertiary {
    border-color: rgba(242, 76, 85, 1) !important;
}
.peer:focus-visible ~ .peer-focus-visible\:border-error {
    border-color: rgba(191, 0, 0, 1) !important;
}
.peer:focus-visible ~ .peer-focus-visible\:border-success {
    border-color: rgba(7, 179, 56, 1) !important;
}
.peer:focus-visible ~ .peer-focus-visible\:border-warning {
    border-color: rgba(255, 149, 0, 1) !important;
}
.peer:focus-visible ~ .peer-focus-visible\:border-loading {
    border-color: rgba(54, 137, 181, 1) !important;
}
.peer:focus-visible ~ .peer-focus-visible\:border-black {
    border-color: rgba(0, 0, 0, 1) !important;
}
.peer:focus-visible ~ .peer-focus-visible\:border-white {
    border-color: rgba(255, 255, 255, 1) !important;
}
.peer:focus-visible ~ .peer-focus-visible\:border-grey {
    border-color: rgba(192, 194, 204, 1) !important;
}
.peer:focus-visible ~ .peer-focus-visible\:border-orange {
    border-color: rgba(255, 149, 0, 1) !important;
}
.peer:focus-visible ~ .peer-focus-visible\:border-lightblue {
    border-color: rgba(90, 200, 250, 1) !important;
}
.peer:focus-visible ~ .peer-focus-visible\:border-blue {
    border-color: rgba(0, 122, 255, 1) !important;
}
.peer:focus-visible ~ .peer-focus-visible\:border-indaco {
    border-color: rgba(88, 86, 214, 1) !important;
}
.peer:focus-visible ~ .peer-focus-visible\:border-purple {
    border-color: rgba(175, 82, 222, 1) !important;
}
.peer:focus-visible ~ .after\:peer-focus-visible\:border-primary::after {
    content: var(--tw-content) !important;
    border-color: rgba(0, 253, 199, 1) !important;
}
.peer:focus-visible ~ .after\:peer-focus-visible\:border-secondary::after {
    content: var(--tw-content) !important;
    border-color: rgba(12, 25, 23, 1) !important;
}
.peer:focus-visible ~ .after\:peer-focus-visible\:border-tertiary::after {
    content: var(--tw-content) !important;
    border-color: rgba(242, 76, 85, 1) !important;
}
.peer:focus-visible ~ .after\:peer-focus-visible\:border-error::after {
    content: var(--tw-content) !important;
    border-color: rgba(191, 0, 0, 1) !important;
}
.peer:focus-visible ~ .after\:peer-focus-visible\:border-success::after {
    content: var(--tw-content) !important;
    border-color: rgba(7, 179, 56, 1) !important;
}
.peer:focus-visible ~ .after\:peer-focus-visible\:border-warning::after {
    content: var(--tw-content) !important;
    border-color: rgba(255, 149, 0, 1) !important;
}
.peer:focus-visible ~ .after\:peer-focus-visible\:border-loading::after {
    content: var(--tw-content) !important;
    border-color: rgba(54, 137, 181, 1) !important;
}
.peer:focus-visible ~ .after\:peer-focus-visible\:border-black::after {
    content: var(--tw-content) !important;
    border-color: rgba(0, 0, 0, 1) !important;
}
.peer:focus-visible ~ .after\:peer-focus-visible\:border-white::after {
    content: var(--tw-content) !important;
    border-color: rgba(255, 255, 255, 1) !important;
}
.peer:focus-visible ~ .after\:peer-focus-visible\:border-grey::after {
    content: var(--tw-content) !important;
    border-color: rgba(192, 194, 204, 1) !important;
}
.peer:focus-visible ~ .after\:peer-focus-visible\:border-orange::after {
    content: var(--tw-content) !important;
    border-color: rgba(255, 149, 0, 1) !important;
}
.peer:focus-visible ~ .after\:peer-focus-visible\:border-lightblue::after {
    content: var(--tw-content) !important;
    border-color: rgba(90, 200, 250, 1) !important;
}
.peer:focus-visible ~ .after\:peer-focus-visible\:border-blue::after {
    content: var(--tw-content) !important;
    border-color: rgba(0, 122, 255, 1) !important;
}
.peer:focus-visible ~ .after\:peer-focus-visible\:border-indaco::after {
    content: var(--tw-content) !important;
    border-color: rgba(88, 86, 214, 1) !important;
}
.peer:focus-visible ~ .after\:peer-focus-visible\:border-purple::after {
    content: var(--tw-content) !important;
    border-color: rgba(175, 82, 222, 1) !important;
}
@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.data-\[state\=open\]\:animate-fade[data-state=open] {
    animation: fade 250ms ease-in-out !important;
}
@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.data-\[state\=delayed-open\]\:animate-fade[data-state=delayed-open] {
    animation: fade 250ms ease-in-out !important;
}
.data-\[state\=open\]\:\[animation-name\:openAccordion\][data-state=open] {
    animation-name: openAccordion;
}
.data-\[state\=closed\]\:\[animation-name\:closeAccordion\][data-state=closed] {
    animation-name: closeAccordion;
}
.group[data-state=open] .group-data-\[state\=open\]\:rotate-180 {
    --tw-rotate: 180deg !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.group[data-state=closed] .group-data-\[state\=closed\]\:rotate-0 {
    --tw-rotate: 0deg !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.ui-selected\:bg-nowr-black[data-headlessui-state~="selected"] {
    background-color: rgba(12, 25, 23, 1) !important;
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-nowr-black {
    background-color: rgba(12, 25, 23, 1) !important;
}
.dark .dark\:border-white\/\[0\.38\] {
    border-color: rgb(255 255 255 / 0.38) !important;
}
.dark .dark\:border-white\/\[0\.74\] {
    border-color: rgb(255 255 255 / 0.74) !important;
}
.dark .dark\:border-white {
    border-color: rgba(255, 255, 255, 1) !important;
}
.dark .dark\:border-primary {
    border-color: rgba(0, 253, 199, 1) !important;
}
.dark .dark\:border-secondary {
    border-color: rgba(12, 25, 23, 1) !important;
}
.dark .dark\:border-tertiary {
    border-color: rgba(242, 76, 85, 1) !important;
}
.dark .dark\:border-black {
    border-color: rgba(0, 0, 0, 1) !important;
}
.dark .dark\:border-grey {
    border-color: rgba(192, 194, 204, 1) !important;
}
.dark .dark\:border-loading {
    border-color: rgba(54, 137, 181, 1) !important;
}
.dark .dark\:border-warning {
    border-color: rgba(255, 149, 0, 1) !important;
}
.dark .dark\:border-success {
    border-color: rgba(7, 179, 56, 1) !important;
}
.dark .dark\:border-error {
    border-color: rgba(191, 0, 0, 1) !important;
}
.dark .dark\:border-lightblue {
    border-color: rgba(90, 200, 250, 1) !important;
}
.dark .dark\:border-blue {
    border-color: rgba(0, 122, 255, 1) !important;
}
.dark .dark\:border-indaco {
    border-color: rgba(88, 86, 214, 1) !important;
}
.dark .dark\:border-purple {
    border-color: rgba(175, 82, 222, 1) !important;
}
.dark .dark\:border-orange {
    border-color: rgba(255, 149, 0, 1) !important;
}
.dark .dark\:border-white\/\[0\.12\] {
    border-color: rgb(255 255 255 / 0.12) !important;
}
.dark .dark\:bg-white\/\[0\.06\] {
    background-color: rgb(255 255 255 / 0.06) !important;
}
.dark .dark\:bg-white\/\[0\.12\] {
    background-color: rgb(255 255 255 / 0.12) !important;
}
.dark .dark\:bg-black\/\[0\.24\] {
    background-color: rgb(0 0 0 / 0.24) !important;
}
.dark .dark\:bg-white\/\[0\.38\] {
    background-color: rgb(255 255 255 / 0.38) !important;
}
.dark .dark\:bg-white {
    background-color: rgba(255, 255, 255, 1) !important;
}
.dark .dark\:bg-primary {
    background-color: rgba(0, 253, 199, 1) !important;
}
.dark .dark\:bg-black {
    background-color: rgba(0, 0, 0, 1) !important;
}
.dark .dark\:bg-blue {
    background-color: rgba(0, 122, 255, 1) !important;
}
.dark .dark\:bg-error {
    background-color: rgba(191, 0, 0, 1) !important;
}
.dark .dark\:bg-grey {
    background-color: rgba(192, 194, 204, 1) !important;
}
.dark .dark\:bg-indaco {
    background-color: rgba(88, 86, 214, 1) !important;
}
.dark .dark\:bg-lightblue {
    background-color: rgba(90, 200, 250, 1) !important;
}
.dark .dark\:bg-loading {
    background-color: rgba(54, 137, 181, 1) !important;
}
.dark .dark\:bg-orange {
    background-color: rgba(255, 149, 0, 1) !important;
}
.dark .dark\:bg-purple {
    background-color: rgba(175, 82, 222, 1) !important;
}
.dark .dark\:bg-secondary {
    background-color: rgba(12, 25, 23, 1) !important;
}
.dark .dark\:bg-success {
    background-color: rgba(7, 179, 56, 1) !important;
}
.dark .dark\:bg-tertiary {
    background-color: rgba(242, 76, 85, 1) !important;
}
.dark .dark\:bg-warning {
    background-color: rgba(255, 149, 0, 1) !important;
}
.dark .dark\:bg-primary-sl06 {
    background-color: rgba(0,238,187,1) !important;
}
.dark .dark\:bg-black-sl06 {
    background-color: rgba(15,15,15,1) !important;
}
.dark .dark\:bg-blue-sl06 {
    background-color: rgba(15,130,255,1) !important;
}
.dark .dark\:bg-error-sl06 {
    background-color: rgba(195,15,15,1) !important;
}
.dark .dark\:bg-grey-sl06 {
    background-color: rgba(180,182,192,1) !important;
}
.dark .dark\:bg-indaco-sl06 {
    background-color: rgba(98,96,216,1) !important;
}
.dark .dark\:bg-lightblue-sl06 {
    background-color: rgba(85,188,235,1) !important;
}
.dark .dark\:bg-loading-sl06 {
    background-color: rgba(66,144,185,1) !important;
}
.dark .dark\:bg-orange-sl06 {
    background-color: rgba(240,140,0,1) !important;
}
.dark .dark\:bg-purple-sl06 {
    background-color: rgba(180,92,224,1) !important;
}
.dark .dark\:bg-secondary-sl06 {
    background-color: rgba(27,39,37,1) !important;
}
.dark .dark\:bg-success-sl06 {
    background-color: rgba(22,184,68,1) !important;
}
.dark .dark\:bg-tertiary-sl06 {
    background-color: rgba(243,87,95,1) !important;
}
.dark .dark\:bg-warning-sl06 {
    background-color: rgba(240,140,0,1) !important;
}
.dark .dark\:bg-primary-sl12 {
    background-color: rgba(0,223,175,1) !important;
}
.dark .dark\:bg-black-sl12 {
    background-color: rgba(31,31,31,1) !important;
}
.dark .dark\:bg-blue-sl12 {
    background-color: rgba(31,138,255,1) !important;
}
.dark .dark\:bg-error-sl12 {
    background-color: rgba(199,31,31,1) !important;
}
.dark .dark\:bg-grey-sl12 {
    background-color: rgba(169,171,180,1) !important;
}
.dark .dark\:bg-indaco-sl12 {
    background-color: rgba(108,106,219,1) !important;
}
.dark .dark\:bg-lightblue-sl12 {
    background-color: rgba(79,176,220,1) !important;
}
.dark .dark\:bg-loading-sl12 {
    background-color: rgba(78,151,190,1) !important;
}
.dark .dark\:bg-orange-sl12 {
    background-color: rgba(224,131,0,1) !important;
}
.dark .dark\:bg-purple-sl12 {
    background-color: rgba(185,103,226,1) !important;
}
.dark .dark\:bg-secondary-sl12 {
    background-color: rgba(41,53,51,1) !important;
}
.dark .dark\:bg-success-sl12 {
    background-color: rgba(37,188,80,1) !important;
}
.dark .dark\:bg-tertiary-sl12 {
    background-color: rgba(244,97,105,1) !important;
}
.dark .dark\:bg-warning-sl12 {
    background-color: rgba(224,131,0,1) !important;
}
.dark .dark\:bg-grey\/\[0\.38\] {
    background-color: rgb(192 194 204 / 0.38) !important;
}
.dark .dark\:from-white\/\[0\.12\] {
    --tw-gradient-from: rgb(255 255 255 / 0.12) !important;
    --tw-gradient-to: rgb(255 255 255 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.dark .dark\:from-black\/\[0\.24\] {
    --tw-gradient-from: rgb(0 0 0 / 0.24) !important;
    --tw-gradient-to: rgb(0 0 0 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.dark .dark\:fill-transparent {
    fill: transparent !important;
}
.dark .dark\:fill-black {
    fill: rgba(0, 0, 0, 1) !important;
}
.dark .dark\:fill-white {
    fill: rgba(255, 255, 255, 1) !important;
}
.dark .dark\:fill-white\/\[0\.87\] {
    fill: rgb(255 255 255 / 0.87) !important;
}
.dark .dark\:fill-white\/\[0\.38\] {
    fill: rgb(255 255 255 / 0.38) !important;
}
.dark .dark\:stroke-white {
    stroke: rgba(255, 255, 255, 1) !important;
}
.dark .dark\:stroke-white\/\[0\.06\] {
    stroke: rgb(255 255 255 / 0.06) !important;
}
.dark .dark\:stroke-white\/\[0\.38\] {
    stroke: rgb(255 255 255 / 0.38) !important;
}
.dark .dark\:text-white\/\[0\.74\] {
    color: rgb(255 255 255 / 0.74) !important;
}
.dark .dark\:text-white {
    color: rgba(255, 255, 255, 1) !important;
}
.dark .dark\:text-white\/\[0\.38\] {
    color: rgb(255 255 255 / 0.38) !important;
}
.dark .dark\:text-primary {
    color: rgba(0, 253, 199, 1) !important;
}
.dark .dark\:text-secondary {
    color: rgba(12, 25, 23, 1) !important;
}
.dark .dark\:text-tertiary {
    color: rgba(242, 76, 85, 1) !important;
}
.dark .dark\:text-success {
    color: rgba(7, 179, 56, 1) !important;
}
.dark .dark\:text-warning {
    color: rgba(255, 149, 0, 1) !important;
}
.dark .dark\:text-error {
    color: rgba(191, 0, 0, 1) !important;
}
.dark .dark\:text-loading {
    color: rgba(54, 137, 181, 1) !important;
}
.dark .dark\:text-black {
    color: rgba(0, 0, 0, 1) !important;
}
.dark .dark\:text-grey {
    color: rgba(192, 194, 204, 1) !important;
}
.dark .dark\:text-orange {
    color: rgba(255, 149, 0, 1) !important;
}
.dark .dark\:text-lightblue {
    color: rgba(90, 200, 250, 1) !important;
}
.dark .dark\:text-blue {
    color: rgba(0, 122, 255, 1) !important;
}
.dark .dark\:text-indaco {
    color: rgba(88, 86, 214, 1) !important;
}
.dark .dark\:text-purple {
    color: rgba(175, 82, 222, 1) !important;
}
.dark .dark\:text-white\/\[0\.12\] {
    color: rgb(255 255 255 / 0.12) !important;
}
.dark .dark\:text-white\/70 {
    color: rgb(255 255 255 / 0.7) !important;
}
.dark .dark\:placeholder\:text-white\/\[0\.38\]::-moz-placeholder {
    color: rgb(255 255 255 / 0.38) !important;
}
.dark .dark\:placeholder\:text-white\/\[0\.38\]::placeholder {
    color: rgb(255 255 255 / 0.38) !important;
}
.dark .dark\:placeholder\:text-\[inherit\]::-moz-placeholder {
    color: inherit !important;
}
.dark .dark\:placeholder\:text-\[inherit\]::placeholder {
    color: inherit !important;
}
@media (min-width: 600px) {
    .sm\:hidden {
        display: none !important;
    }
    .sm\:w-max {
        width: -moz-max-content !important;
        width: max-content !important;
    }
    .sm\:w-auto {
        width: auto !important;
    }
    .sm\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
    .sm\:flex-row {
        flex-direction: row !important;
    }
    .sm\:items-center {
        align-items: center !important;
    }
    .sm\:justify-between {
        justify-content: space-between !important;
    }
    .sm\:px-10 {
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important;
    }
    .sm\:px-4 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    .sm\:px-0 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .sm\:pt-4 {
        padding-top: 1rem !important;
    }
    .sm\:pt-2 {
        padding-top: 0.5rem !important;
    }
}
@media (min-width: 900px) {
    .md\:absolute {
        position: absolute !important;
    }
    .md\:right-6 {
        right: 1.5rem !important;
    }
    .md\:top-6 {
        top: 1.5rem !important;
    }
    .md\:mb-4 {
        margin-bottom: 1rem !important;
    }
    .md\:mt-6 {
        margin-top: 1.5rem !important;
    }
    .md\:mt-4 {
        margin-top: 1rem !important;
    }
    .md\:flex {
        display: flex !important;
    }
    .md\:\!h-\[300px\] {
        height: 300px !important;
    }
    .md\:\!h-\[400px\] {
        height: 400px !important;
    }
    .md\:h-auto {
        height: auto !important;
    }
    .md\:h-8 {
        height: 2rem !important;
    }
    .md\:h-full {
        height: 100% !important;
    }
    .md\:max-h-\[400px\] {
        max-height: 400px !important;
    }
    .md\:max-h-\[min\(38vh\2c 300px\)\] {
        max-height: min(38vh,300px) !important;
    }
    .md\:w-\[102px\] {
        width: 102px !important;
    }
    .md\:w-auto {
        width: auto !important;
    }
    .md\:w-8 {
        width: 2rem !important;
    }
    .md\:w-\[50\%\] {
        width: 50% !important;
    }
    .md\:min-w-\[102px\] {
        min-width: 102px !important;
    }
    .md\:max-w-\[112px\] {
        max-width: 112px !important;
    }
    .md\:max-w-\[520px\] {
        max-width: 520px !important;
    }
    .md\:flex-auto {
        flex: 1 1 auto !important;
    }
    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
    .md\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
    }
    .md\:flex-row {
        flex-direction: row !important;
    }
    .md\:flex-nowrap {
        flex-wrap: nowrap !important;
    }
    .md\:items-start {
        align-items: flex-start !important;
    }
    .md\:items-center {
        align-items: center !important;
    }
    .md\:gap-\[60px\] {
        gap: 60px !important;
    }
    .md\:gap-10 {
        gap: 2.5rem !important;
    }
    .md\:gap-6 {
        gap: 1.5rem !important;
    }
    .md\:gap-5 {
        gap: 1.25rem !important;
    }
    .md\:rounded-none {
        border-radius: 0px !important;
    }
    .md\:px-4 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    .md\:text-start {
        text-align: start !important;
    }
    .md\:shadow-\[0px_4px_16px_rgba\(0\2c _0\2c _0\2c _0\.06\)\] {
        --tw-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06) !important;
        --tw-shadow-colored: 0px 4px 16px var(--tw-shadow-color) !important;
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    }
}
@media (min-width: 1200px) {
    .lg\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
    }
    .lg\:pl-2 {
        padding-left: 0.5rem !important;
    }
}
.\[\&_\>_\*\]\:font-bold > * {
    font-weight: 700 !important;
}
.\[\&_\*\:not\(\.loadingSpinner\)\]\:invisible *:not(.loadingSpinner) {
    visibility: hidden !important;
}
.\[\&_path\]\:\[fill\:\#000\] path {
    fill: #000;
}
.\[\&_\.Popover-arrow\]\:data-\[align\=end\]\:data-\[side\=right\]\:right-2[data-side=right][data-align=end] .Popover-arrow {
    right: 0.5rem !important;
}
.\[\&_\.Popover-arrow\]\:data-\[align\=start\]\:data-\[side\=top\]\:left-2[data-side=top][data-align=start] .Popover-arrow {
    left: 0.5rem !important;
}
.\[\&_\.Popover-arrow\]\:data-\[align\=end\]\:data-\[side\=top\]\:right-2[data-side=top][data-align=end] .Popover-arrow {
    right: 0.5rem !important;
}
.\[\&_\.Popover-arrow\]\:data-\[align\=start\]\:data-\[side\=left\]\:right-2[data-side=left][data-align=start] .Popover-arrow {
    right: 0.5rem !important;
}
.\[\&_\.Popover-arrow\]\:data-\[align\=end\]\:data-\[side\=left\]\:left-2[data-side=left][data-align=end] .Popover-arrow {
    left: 0.5rem !important;
}
.\[\&_\.Popover-arrow\]\:data-\[align\=start\]\:data-\[side\=bottom\]\:right-2[data-side=bottom][data-align=start] .Popover-arrow {
    right: 0.5rem !important;
}
.\[\&_\.Popover-arrow\]\:data-\[align\=end\]\:data-\[side\=bottom\]\:left-2[data-side=bottom][data-align=end] .Popover-arrow {
    left: 0.5rem !important;
}
.\[\&_\>\:first-child\]\:mr-2 >:first-child {
    margin-right: 0.5rem !important;
}
.\[\&_\>\:last-child\]\:ml-2 >:last-child {
    margin-left: 0.5rem !important;
}
.\[\&_\*\]\:origin-center * {
    transform-origin: center !important;
}
@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
.\[\&_\*\]\:animate-spin * {
    animation: spin 1s linear infinite !important;
}
.\[\&_\*\]\:\[animation-duration\:_4000ms\] * {
    animation-duration: 4000ms;
}
.\[\&_\.Tooltip-arrow\]\:data-\[align\=end\]\:data-\[side\=right\]\:right-2[data-side=right][data-align=end] .Tooltip-arrow {
    right: 0.5rem !important;
}
.\[\&_\.Tooltip-arrow\]\:data-\[align\=start\]\:data-\[side\=left\]\:right-2[data-side=left][data-align=start] .Tooltip-arrow {
    right: 0.5rem !important;
}
.\[\&_\.Tooltip-arrow\]\:data-\[align\=end\]\:data-\[side\=left\]\:left-2[data-side=left][data-align=end] .Tooltip-arrow {
    left: 0.5rem !important;
}
.\[\&\>div\]\:bg-transparent>div {
    background-color: transparent !important;
}
.\[\&\>div\]\:pr-4>div {
    padding-right: 1rem !important;
}
.\[\&\>div\>div\>div\]\:p-0>div>div>div {
    padding: 0px !important;
}
.\[\&_\.Accordion-trigger\]\:rounded-b-xl .Accordion-trigger {
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
}
.\[\&_\.Accordion-trigger\]\:py-2 .Accordion-trigger {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}
.\[\&_\.Accordion-trigger\]\:px-\[10px\] .Accordion-trigger {
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.\[\&_\.Accordion-trigger\:hover\]\:bg-primary\/\[0\.12\] .Accordion-trigger:hover {
    background-color: rgb(0 253 199 / 0.12) !important;
}
.\[\&_\.Accordion-trigger\:active\]\:bg-primary\/\[0\.38\] .Accordion-trigger:active {
    background-color: rgb(0 253 199 / 0.38) !important;
}
.\[\&\>div\>div\]\:pl-4>div>div {
    padding-left: 1rem !important;
}
.\[\&_\.Accordion-trigger\[data-state\=\'open\'\]_\.icon\]\:\!bg-none .Accordion-trigger[data-state='open'] .icon {
    background-image: none !important;
}
.\[\&_\.Accordion-trigger\[data-state\=\'open\'\]_p\]\:font-normal .Accordion-trigger[data-state='open'] p {
    font-weight: 400 !important;
}
.\[\&_p\]\:\!font-bold p {
    font-weight: 700 !important;
}
.\[\&_\.MuiInput-input\]\:text-black\/\[0\.38\] .MuiInput-input {
    color: rgb(0 0 0 / 0.38) !important;
}
.\[\&_svg\]\:pointer-events-none svg {
    pointer-events: none !important;
}
.\[\&_input\]\:cursor-pointer input {
    cursor: pointer !important;
}

.rdrCalendarWrapper {
  box-sizing: border-box;
  background: #ffffff;
  display: inline-flex;
  flex-direction: column;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.rdrDateDisplay{
  display: flex;
  justify-content: space-between;
}

.rdrDateDisplayItem{
  flex: 1 1;
  width: 0;
  text-align: center;
  color: inherit;
}

.rdrDateDisplayItem + .rdrDateDisplayItem{
    margin-left: 0.833em;
  }

.rdrDateDisplayItem input{
    text-align: inherit
  }

.rdrDateDisplayItem input:disabled{
      cursor: default;
    }

.rdrDateDisplayItemActive{}

.rdrMonthAndYearWrapper {
  box-sizing: inherit;
  display: flex;
  justify-content: space-between;
}

.rdrMonthAndYearPickers{
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdrMonthPicker{}

.rdrYearPicker{}

.rdrNextPrevButton {
  box-sizing: inherit;
  cursor: pointer;
  outline: none;
}

.rdrPprevButton {}

.rdrNextButton {}

.rdrMonths{
  display: flex;
}

.rdrMonthsVertical{
  flex-direction: column;
}

.rdrMonthsHorizontal > div > div > div{
  display: flex;
  flex-direction: row;
}

.rdrMonth{
  width: 27.667em;
}

.rdrWeekDays{
  display: flex;
}

.rdrWeekDay {
  flex-basis: calc(100% / 7);
  box-sizing: inherit;
  text-align: center;
}

.rdrDays{
  display: flex;
  flex-wrap: wrap;
}

.rdrDateDisplayWrapper{}

.rdrMonthName{}

.rdrInfiniteMonths{
  overflow: auto;
}

.rdrDateRangeWrapper{
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.rdrDateInput {
  position: relative;
}

.rdrDateInput input {
    outline: none;
  }

.rdrDateInput .rdrWarning {
    position: absolute;
    font-size: 1.6em;
    line-height: 1.6em;
    top: 0;
    right: .25em;
    color: #FF0000;
  }

.rdrDay {
  box-sizing: inherit;
  width: calc(100% / 7);
  position: relative;
  font: inherit;
  cursor: pointer;
}

.rdrDayNumber {
  display: block;
  position: relative;
}

.rdrDayNumber span{
    color: #1d2429;
  }

.rdrDayDisabled {
  cursor: not-allowed;
}

@supports (-ms-ime-align: auto) {
  .rdrDay {
    flex-basis: 14.285% !important;
  }
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
  pointer-events: none;
}

.rdrInRange{}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
  pointer-events: none;
}

.rdrDayHovered{}

.rdrDayActive{}

.rdrDateRangePickerWrapper{
  display: inline-flex;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.rdrDefinedRangesWrapper{}

.rdrStaticRanges{
  display: flex;
  flex-direction: column;
}

.rdrStaticRange{
  font-size: inherit;
}

.rdrStaticRangeLabel{}

.rdrInputRanges{}

.rdrInputRange{
  display: flex;
}

.rdrInputRangeInput{}

.rdrCalendarWrapper{
  color: #000000;
  font-size: 12px;
}

.rdrDateDisplayWrapper{
  background-color: rgb(239, 242, 247);
}

.rdrDateDisplay{
  margin: 0.833em;
}

.rdrDateDisplayItem{
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid transparent;
}

.rdrDateDisplayItem input{
    cursor: pointer;
    height: 2.5em;
    line-height: 2.5em;
    border: 0px;
    background: transparent;
    width: 100%;
    color: #849095;
  }

.rdrDateDisplayItemActive{
  border-color: currentColor;
}

.rdrDateDisplayItemActive input{
    color: #7d888d
  }

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 60px;
  padding-top: 10px;
}

.rdrMonthAndYearPickers{
  font-weight: 600;
}

.rdrMonthAndYearPickers select{
    -moz-appearance: none;
         appearance: none;
    -webkit-appearance: none;
    border: 0;
    background: transparent;
    padding: 10px 30px 10px 10px;
    border-radius: 4px;
    outline: 0;
    color: #3e484f;
    background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
    background-position: right 8px center;
    cursor: pointer;
    text-align: center
  }

.rdrMonthAndYearPickers select:hover{
      background-color: rgba(0,0,0,0.07);
    }

.rdrMonthPicker, .rdrYearPicker{
  margin: 0 5px
}

.rdrNextPrevButton {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  border-radius: 5px;
  background: #EFF2F7
}

.rdrNextPrevButton:hover{
    background: #E1E7F0;
  }

.rdrNextPrevButton i {
    display: block;
    width: 0;
    height: 0;
    padding: 0;
    text-align: center;
    border-style: solid;
    margin: auto;
    transform: translate(-3px, 0px);
  }

.rdrPprevButton i {
    border-width: 4px 6px 4px 4px;
    border-color: transparent rgb(52, 73, 94) transparent transparent;
    transform: translate(-3px, 0px);
  }

.rdrNextButton i {
    margin: 0 0 0 7px;
    border-width: 4px 4px 4px 6px;
    border-color: transparent transparent transparent rgb(52, 73, 94);
    transform: translate(3px, 0px);
  }

.rdrWeekDays {
  padding: 0 0.833em;
}

.rdrMonth{
  padding: 0 0.833em 1.666em 0.833em;
}

.rdrMonth .rdrWeekDays {
    padding: 0;
  }

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName{
  display: none;
}

.rdrWeekDay {
  font-weight: 400;
  line-height: 2.667em;
  color: rgb(132, 144, 149);
}

.rdrDay {
  background: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: 0;
  padding: 0;
  line-height: 3.000em;
  height: 3.000em;
  text-align: center;
  color: #1d2429
}

.rdrDay:focus {
    outline: 0;
  }

.rdrDayNumber {
  outline: 0;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  top: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrDayToday .rdrDayNumber span{
  font-weight: 500
}

.rdrDayToday .rdrDayNumber span:after{
    content: '';
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background: #3d91ff;
  }

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after{
      background: #fff;
    }

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
          color: rgba(255, 255, 255, 0.85);
        }

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
  background: currentColor;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 5px;
}

.rdrSelected{
  left: 2px;
  right: 2px;
}

.rdrInRange{}

.rdrStartEdge{
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px;
}

.rdrEndEdge{
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px;
}

.rdrSelected{
  border-radius: 1.042em;
}

.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge{
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
  }

.rdrDayEndOfMonth .rdrInRange,  .rdrDayEndOfMonth .rdrStartEdge,  .rdrDayEndOfWeek .rdrInRange,  .rdrDayEndOfWeek .rdrStartEdge{
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 2px;
  }

.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview{
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    border-left-width: 1px;
    left: 0px;
  }

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview{
   border-top-right-radius: 1.333em;
   border-bottom-right-radius: 1.333em;
   border-right-width: 1px;
   right: 0px;
 }

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
  background: rgba(255, 255, 255, 0.09);
  position: absolute;
  top: 3px;
  left: 0px;
  right: 0px;
  bottom: 3px;
  pointer-events: none;
  border: 0px solid currentColor;
  z-index: 1;
}

.rdrDayStartPreview{
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0px;
}

.rdrDayInPreview{
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.rdrDayEndPreview{
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 0px;
}

.rdrDefinedRangesWrapper{
  font-size: 12px;
  width: 226px;
  border-right: solid 1px #eff2f7;
  background: #fff;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected{
    color: currentColor;
    font-weight: 600;
  }

.rdrStaticRange{
  border: 0;
  cursor: pointer;
  display: block;
  outline: 0;
  border-bottom: 1px solid #eff2f7;
  padding: 0;
  background: #fff
}

.rdrStaticRange:hover .rdrStaticRangeLabel,.rdrStaticRange:focus .rdrStaticRangeLabel{
      background: #eff2f7;
    }

.rdrStaticRangeLabel{
  display: block;
  outline: 0;
  line-height: 18px;
  padding: 10px 20px;
  text-align: left;
}

.rdrInputRanges{
  padding: 10px 0;
}

.rdrInputRange{
  align-items: center;
  padding: 5px 20px;
}

.rdrInputRangeInput{
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;
  border: solid 1px rgb(222, 231, 235);
  margin-right: 10px;
  color: rgb(108, 118, 122)
}

.rdrInputRangeInput:focus, .rdrInputRangeInput:hover{
    border-color: rgb(180, 191, 196);
    outline: 0;
    color: #333;
  }

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
  content: '';
  border: 1px solid currentColor;
  border-radius: 1.333em;
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0px;
  right: 0px;
  background: transparent;
}

.rdrDayPassive{
  pointer-events: none;
}

.rdrDayPassive .rdrDayNumber span{
    color: #d5dce0;
  }

.rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview{
    display: none;
  }

.rdrDayDisabled {
  background-color: rgb(248, 248, 248);
}

.rdrDayDisabled .rdrDayNumber span{
    color: #aeb9bf;
  }

.rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview{
    filter: grayscale(100%) opacity(60%);
  }

.rdrMonthName{
  text-align: left;
  font-weight: 600;
  color: #849095;
  padding: 0.833em;
}

