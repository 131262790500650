@import "react-spring-bottom-sheet";

[data-rsbs-overlay] {
	border-top-left-radius: var(--rsbs-overlay-rounded);
	border-top-right-radius: var(--rsbs-overlay-rounded);
	display: flex;
	background: var(--rsbs-bg);
	flex-direction: column;
	height: var(--rsbs-overlay-h);
	transform: translate3d(0, var(--rsbs-overlay-translate-y), 0);
	will-change: height;
}
[data-rsbs-overlay]:focus {
	outline: none;
}
[data-rsbs-is-blocking="false"] [data-rsbs-overlay] {
	box-shadow: 0 -5px 60px 0 rgba(38, 89, 115, 0.11), 0 -1px 0 rgba(38, 89, 115, 0.05);
}
[data-rsbs-overlay],
[data-rsbs-root]:after {
	max-width: 1200px !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
	z-index: 1200 !important;
	overscroll-behavior: none;
	touch-action: none;
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}
[data-rsbs-backdrop] {
	top: -60px;
	bottom: -60px;
	background-color: var(--rsbs-backdrop-bg);
	will-change: opacity;
	cursor: pointer;
	opacity: 1;
}
[data-rsbs-is-dismissable="false"] [data-rsbs-backdrop] {
	cursor: ns-resize;
}

[data-rsbs-root]:after {
	content: "";
	pointer-events: none;
	background: var(--rsbs-bg);
	height: 1px;
	transform-origin: bottom;
	transform: scale3d(1, var(--rsbs-antigap-scale-y), 1);
	will-change: transform;
}
[data-rsbs-footer],
[data-rsbs-header] {
	flex-shrink: 0;
	cursor: ns-resize;
	padding: 16px;
	box-shadow: none;
}
[data-rsbs-header] {
	text-align: center;
	user-select: none;
	box-shadow: none !important;
	z-index: 1;
	padding-top: calc(20px + env(safe-area-inset-top));
	padding-bottom: 8px;
}
[data-rsbs-header]:before {
	position: absolute;
	content: "";
	display: block;
	width: 36px;
	height: 4px;
	top: calc(8px + env(safe-area-inset-top));
	left: 50%;
	transform: translateX(-50%);
	border-radius: 2px;
	background-color: var(--rsbs-handle-bg);
}
@media (min-resolution: 2dppx) {
	[data-rsbs-header]:before {
		transform: translateX(-50%) scaleY(0.75);
	}
}
[data-rsbs-has-header="false"] [data-rsbs-header] {
	box-shadow: none;
	padding-top: calc(12px + env(safe-area-inset-top));
}
[data-rsbs-scroll] {
	flex-shrink: 1;
	flex-grow: 1;
	-webkit-tap-highlight-color: revert;
	-webkit-touch-callout: revert;
	-webkit-user-select: auto;
	-ms-user-select: auto;
	user-select: auto;
	overflow: auto;
	overscroll-behavior: contain;
	-webkit-overflow-scrolling: touch;
}
[data-rsbs-scroll]:focus {
	outline: none;
}
[data-rsbs-has-footer="false"] [data-rsbs-content] {
	padding-bottom: env(safe-area-inset-bottom);
}
[data-rsbs-content] {
	/* The overflow hidden is to ensure any margin on child nodes are included when the resize observer is measuring the height */
	overflow: hidden;
}
[data-rsbs-footer] {
	box-shadow: none !important;
	overflow: hidden;
	z-index: 1;
	padding-bottom: calc(16px + env(safe-area-inset-bottom));
}

[data-rsbs-is-dismissable="true"],
[data-rsbs-is-dismissable="false"]:matches([data-rsbs-state="opening"], [data-rsbs-state="closing"]) {
	& :matches([data-rsbs-header], [data-rsbs-scroll], [data-rsbs-footer]) > * {
		opacity: var(--rsbs-content-opacity);
	}
	& [data-rsbs-backdrop] {
		opacity: var(--rsbs-backdrop-opacity);
	}
}

[data-rsbs-state="closed"],
[data-rsbs-state="closing"] {
	/* Allows interactions on the rest of the page before the close transition is finished */
	pointer-events: none;
}
